import React, { Component } from 'react';
import axios from '../../api/axios';
import AudioPlayer from "../audio/AudioPlayer";
import { Link } from 'react-router-dom';
import SignOut from './SignOut';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveIndex: "0",
      show: false,
      count: 0, // Initialize visitor count state
      playMusic: false,
    };

    // Dynamically set the API URL based on the environment
    if (window.location.hostname === 'localhost') {
      this.apiUrl = 'http://localhost:8888/.netlify/functions/visitor';
      console.log('header: you are using localhost');
    } else {
      this.apiUrl = 'https://pixelheads-server.netlify.app/.netlify/functions/visitor';
      console.log('header: you are using prod');
    }
  }

  // Toggle active class
  toggleActiveClass = (index) => {
    this.setState({ show: false }); // Close the mobile menu (if open)
    
    setTimeout(() => {
      this.setState({ isActiveIndex: index.toString() });
    }, 800); // delay to stop jumping
  };

  // Change active menu link
  changeActiveMenu = (index) => {
    if (index >= 0 && index <= 5) {
      this.setState({ isActiveIndex: index.toString() });
    }
  };

  // Toggle mobile menu visibility
  toggleMenu = () => {
    this.setState(prevState => ({ show: !prevState.show }));
  };

  fetchVisitorCount = async () => {
    try {
      const response = await axios.post('/visitor', {}, {
        headers: { 'Content-Type': 'application/json' }
      });
  
      this.setState({ count: response.data.count });
    } catch (error) {
      console.error("🚨 Error fetching visitor count:", error);
    }
  };  

  // FULL GEOLOCATION
  // fetchVisitorCount = async () => {
  //   try {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         async (position) => {
  //           const { latitude, longitude } = position.coords;
  //           const response = await axios.post('/visitor', {
  //             geolocation: { lat: latitude, lon: longitude }
  //           }, {
  //             headers: { 'Content-Type': 'application/json' }
  //           });
  
  //           this.setState({ count: response.data.count });
  //         },
  //         async (error) => {
  //           const response = await axios.post('/visitor', { geolocation: null }, {
  //             headers: { 'Content-Type': 'application/json' }
  //           });
  //           this.setState({ count: response.data.count });
  //         }
  //       );
  //     } else {
  //       const response = await axios.post('/visitor', { geolocation: null }, {
  //         headers: { 'Content-Type': 'application/json' }
  //       });
  //       this.setState({ count: response.data.count });
  //     }
  //   } catch (error) {
  //   }
  // };
  
  // Fallback if geolocation is blocked
  fetchCountWithoutLocation = async () => {
    try {
      const response = await axios.put('/visitor', { geolocation: null });
      this.setState({ count: response.data.count });
    } catch (error) {
      console.error("Error fetching visitor count:", error);
    }
  };
  
  componentDidMount() {
    this.fetchVisitorCount();
  }

  handleToggleMusic = () => {
    this.setState(prevState => ({
      playMusic: !prevState.playMusic,
    }));
  };

  render() {
    const { isActiveIndex, show, count } = this.state;

    return (
      <header className="main-header flex-group">
        <div className="flex-group flex-align-center flex-start flex-nowrap">
          <div className="device desktop material-icons" title="Desktop">desktop_windows</div>
          <div className="device tablet material-icons" title="Tablet">tablet_mac</div>
          <div className="device mobile material-icons" title="Mobile">phone_iphone</div>
          <nav className="main-nav">
            <a
              className={isActiveIndex === "0" ? 'active home' : ''}
              href='#section-home'
              onClick={() => this.toggleActiveClass("0")}
            >
              Home
            </a>
            <a
              className={isActiveIndex === "1" ? 'active about' : ''}
              href='#section-about'
              onClick={() => this.toggleActiveClass("1")}
            >
              About
            </a>
            <a
              className={isActiveIndex === "2" ? 'active about' : ''}
              href='#section-approach'
              onClick={() => this.toggleActiveClass("2")}
            >
              Approach
            </a>
            <a
              className={isActiveIndex === "3" ? 'active projects' : ''}
              href='#section-projects'
              onClick={() => this.toggleActiveClass("3")}
            >
              Projects
            </a>
            <a
              className={isActiveIndex === "4" ? 'active contact' : ''}
              href='#section-contact'
              onClick={() => this.toggleActiveClass("4")}
            >
              Contact
            </a>
          </nav>
        </div>

        {/* Visitor Count Display */}
        <section className="right-section flex-group flex-end flex-nowrap flex-align-center position-relative">
          <span className='visitor-count'>You are visitor ({count})</span>
          <AudioPlayer onToggleMusic={this.handleToggleMusic} />
          <div title="Welcome to PixelHeads" className="logo"></div>
            {
              /*
              <Link className="flex-group flex-align-center" to="/blog-home">
                <span className="material-icons padding-right-tiny dark-grey">edit_note</span>
                Blog
              </Link>

              <SignOut />
              */
            }
             <div className={"main-nav-small " + (show ? 'show-menu' : '')}>
              <i onClick={this.toggleMenu} className="menu-icon" alt="">
                <div></div><div></div><div></div>
              </i>
              <div onClick={this.toggleMenu} className="tint"></div>
              <nav>
                <a
                  className={isActiveIndex === "0" ? 'active home' : ''}
                  href='#section-home'
                  onClick={() => this.toggleActiveClass("0")}
                >
                  Home
                </a>
                <a
                  className={isActiveIndex === "1" ? 'active about' : ''}
                  href='#section-about'
                  onClick={() => this.toggleActiveClass("1")}
                >
                  About
                </a>
                <a
                  className={isActiveIndex === "2" ? 'active about' : ''}
                  href='#section-approach'
                  onClick={() => this.toggleActiveClass("2")}
                >
                  Approach
                </a>
                <a
                  className={isActiveIndex === "3" ? 'active projects' : ''}
                  href='#section-projects'
                  onClick={() => this.toggleActiveClass("3")}
                >
                  Projects
                </a>
                <a
                  className={isActiveIndex === "4" ? 'active contact' : ''}
                  href='#section-contact'
                  onClick={() => this.toggleActiveClass("4")}
                >
                  Contact
                </a>
              </nav>
            </div>
        </section>
      </header>
    );
  }
}

export default Header;
