import React from 'react';
import { Link } from 'react-router-dom';
import SignOut from '../header/SignOut';

function ProjectsNav() {
  return (
    <header className="main-header flex-group flex-align-center flex-nowrap">
      <nav className="main-nav flex-group flex-start flex-align-center yellow">
        
        <Link className="back-link flex-group flex-start flex-align-center" to="/">
          <span className="material-icons">home</span>
          <span>Go back home</span>
        </Link>
      </nav>
      <div className="jump-to-top">
        <a className="top" href='#top'>
          <div className="top-arrow"></div>
          <div className="bottom-arrow"></div>
          <label>top</label>
        </a>
      </div>
      <div className="right-section flex-group flex-end flex-nowrap flex-align-center position-relative">
        {
          /*
          <Link className="flex-group flex-align-center" to="/blog-home">
            <span className="material-icons padding-right-tiny dark-grey">edit_note</span>
            Blog
          </Link>

        <SignOut />
          */
        }
        <div title="Welcome to PixelHeads" className="logo"></div>
      </div>
    </header>
  );
}

export default ProjectsNav;
