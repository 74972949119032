import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import ActiveSection from '../../scripts/active-section';
import NavNextJS from '../header/NavNextJS';

// Images
import NextBasicPage from '../../images/interviews/next-basic-page.png';
import NextDynamicRouting from '../../images/interviews/next-dynamic-routing.png';
import NextSSRendering from '../../images/interviews/next-ss-rendering.png';
import NextSSG from '../../images/interviews/next-ssg.png';
import NextAPIRoutes from '../../images/interviews/next-api.png';
import NextFetch from '../../images/interviews/next-fetch.png';
import NextCSFetch from '../../images/interviews/next-cs-fetch.png';
import NextMiddleware from '../../images/interviews/next-middle.png';
import NextMiddlewareConfig from '../../images/interviews/next-middle-config.png';
import NextContextProvider from '../../images/interviews/next-context-provider.png';
import NextUseComp from '../../images/interviews/next-use-comp.png';
import NextMidAuth from '../../images/interviews/next-mid-auth.png';
import NextMidApplyConfig from '../../images/interviews/next-mid-apply-conf.png';

import NextInstall from '../../images/interviews/next-install.png';
import NextCreateTheme from '../../images/interviews/next-create-theme.png';
import NextCreateAPI from '../../images/interviews/next-create-api.png';
import NextCreateHome from '../../images/interviews/next-create-home.png';
import NextAppSetup from '../../images/interviews/next-app-setup.png';
import NextRun from '../../images/interviews/next-run.png';

const NextJSConcepts = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <NavNextJS ref={menuRef} />
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element className='element'>
          <section id="section-1" className='content-section flex-group flex-center'>
            <ActiveSection index="1" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Pages & Routing</h2>
                <h3>What</h3>
                <p>
                  Next.js is a React framework that enhances React by adding server-side 
                  rendering (SSR), static site generation (SSG), API routes, and routing 
                  out-of-the-box.
                </p>
                <h3>Why</h3>
                <p>
                  It optimizes performance, improves SEO, and simplifies 
                  backend integration, making it ideal for fast, scalable, and production-ready 
                  web applications with minimal configuration. It eliminates the need for 
                  react-router-dom, making navigation simpler.
                </p>
                <h3>How</h3>
                <strong>Basic Page (pages/index.js):</strong>
                <img className="mw50" src={NextBasicPage} />
                <strong>Dynamic Routing (pages/post/[id].js):</strong>
                <img className="mw50" src={NextDynamicRouting} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-2" className='content-section flex-group flex-center'>
            <ActiveSection index="2" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Server-Side Rendering (SSR)</h2>
                <h3>What</h3>
                <p>
                  Pages are generated on each request using getServerSideProps.
                </p>
                <h3>Why</h3>
                <p>
                  Useful for dynamic data like user-specific content.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={NextSSRendering} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-3" className='content-section flex-group flex-center'>
            <ActiveSection index="3" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Static Site Generation (SSG)</h2>
                <h3>What</h3>
                <p>
                  Pages are pre-built at build time using getStaticProps.
                </p>
                <h3>Why</h3>
                <p>
                  Faster performance for content that doesn’t change often.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={NextSSG} />
                <strong>(revalidate: 10 ensures the page updates every 10 seconds.)</strong>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-4" className='content-section flex-group flex-center'>
            <ActiveSection index="4" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>API Routes</h2>
                <h3>What</h3>
                <p>
                  Next.js lets you create backend APIs inside the pages/api/ directory.
                </p>
                <h3>Why</h3>
                <p>
                  No need for an external backend; use it for authentication, CRUD operations, etc..
                </p>
                <h3>How</h3>
                <strong>pages/api/hello.js:</strong>
                <img className="mw50" src={NextAPIRoutes} />
                <strong>Fetching from Frontend:</strong>
                <img className="mw50" src={NextFetch} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-5" className='content-section flex-group flex-center'>
            <ActiveSection index="5" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Client-Side Fetching (useEffect)</h2>
                <h3>What</h3>
                <p>
                  Fetch data on the client instead of using SSR/SSG.
                </p>
                <h3>Why</h3>
                <p>
                  Useful for user-interaction-based content.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={NextCSFetch} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-6" className='content-section flex-group flex-center'>
            <ActiveSection index="6" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>API with Middleware</h2>
                <h3>What</h3>
                <p>
                  Middleware intercepts requests before they reach API routes or pages.
                </p>
                <h3>Why</h3>
                <p>
                  Useful for authentication, logging, and rate-limiting.
                </p>
                <h3>How</h3>
                <strong>middleware.js:</strong>
                <img className="mw50" src={NextMiddleware} />
                <strong>next.config.js:</strong>
                <img className="mw50" src={NextMiddlewareConfig} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-7" className='content-section flex-group flex-center'>
            <ActiveSection index="7" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Global State Management (Context API)</h2>
                <h3>What</h3>
                <p>
                  Use React’s useContext to manage global state.
                </p>
                <h3>Why</h3>
                <p>
                  Avoids prop drilling in deeply nested components.
                </p>
                <h3>How</h3>
                <div className='flex-group flex-start flex-nowrap'>
                  <div className='flex-group flex-column'>
                    <strong>Context Provider (context/ThemeContext.js):</strong>
                    <img className="mw40" src={NextContextProvider} />
                  </div>
                  <div className='flex-group flex-column'>
                    <strong>Use in Component:</strong>
                    <img className="mw40" src={NextUseComp} />
                  </div>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-8" className='content-section flex-group flex-center'>
            <ActiveSection index="8" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Middleware-based Authentication</h2>
                <h3>What</h3>
                <p>
                  Restrict access based on session tokens.
                </p>
                <h3>Why</h3>
                <p>
                  Essential for protected routes like a dashboard.
                </p>
                <h3>How</h3>
                <strong>Middleware (middleware.js):</strong>
                <img className="mw50" src={NextMidAuth} />
                <strong>Apply in next.config.js:</strong>
                <img className="mw50" src={NextMidApplyConfig} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-9" className='content-section flex-group flex-center'>
            <ActiveSection index="9" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Install Next.js & Create Theme</h2>
                <p>This Next.js project covers all concepts!</p>
                <strong>Install Next.js:</strong>
                <img className="mw50" src={NextInstall} />
                <h3>Create Theme Context</h3>
                <strong>context/ThemeContext.js:</strong>
                <img className="mw50" src={NextCreateTheme} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-10" className='content-section flex-group flex-center'>
            <ActiveSection index="10" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Create API Route & Home Page</h2>
                <strong>pages/api/theme.js:</strong>
                <img className="mw50" src={NextCreateAPI} />
                <strong>pages/index.js:</strong>
                <img className="mw50" src={NextCreateHome} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-11" className='content-section flex-group flex-center'>
            <ActiveSection index="11" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>App.js Setup & Run</h2>
                <strong>pages/_app.js:</strong>
                <img className="mw50" src={NextAppSetup} />
                <strong>Run the Project</strong>
                <img className="mw50" src={NextRun} />
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default NextJSConcepts;