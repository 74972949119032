import axios from 'axios';

// Set the base URL depending on the environment
const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://pixelheads-server.netlify.app/.netlify/functions' // Production URL
    : 'http://localhost:8888/.netlify/functions'; // Local development URL (no `/api/`)

// Default axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Private axios instance (with credentials support)
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true, // Allow sending cookies with requests
});

export default axiosInstance;

// Function to call the visitor count endpoint with GET method
export const updateVisitorCount = async () => {
  try {
    const response = await axiosInstance.get('/visitor');  // Ensure it's a GET request
    console.log('Visitor count:', response.data.count); // Log the visitor count
  } catch (error) {
    console.error('Error fetching visitor count:', error);
  }
};
