import React, { useEffect } from 'react';
import ReactBody from 'react-body';
import AOS from "aos";
import "aos/dist/aos.css";

import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';

import Header from '../header/Header';
import IntroSection from './IntroSection';
import AboutMike from './About';
import Approach from './Approach';
import MikesProjects from './Projects';
import ContactMike from './Contact';

import ActiveSection from '../../scripts/active-section';

const Home = () => {
  var menuRef = React.createRef();

  useEffect(() => {
      AOS.init({
          duration: 2000,
          once: true,
      });
      AOS.refresh();
  }, []);

  return (
    <section className="content">
      <ReactBody className="home-page" />
      <StickyContainer>
        {/* Section: Header */}
        <section id="section-home">
          <Element name="section-home">
            <ActiveSection index="0" refToMenu={menuRef}>
              <Header ref={menuRef} />
            </ActiveSection>
          </Element>
        </section>

        {/* Section: Intro */}
        <Element name="section-home">
          <section className="new-section home" data-aos="fade-in">
            <IntroSection />
          </section>
        </Element>

        {/* Section: About */}
        <Element name="section-about">
          <section className="new-section about" id="section-about" data-aos="fade-in">
            <ActiveSection index="1" refToMenu={menuRef}>
              <AboutMike />
            </ActiveSection>
          </section>
        </Element>

        {/* Section: Approach */}
        <Element name="section-approach">
          <section className="new-section approach" id="section-approach" data-aos="fade-in">
            <ActiveSection index="2" refToMenu={menuRef}>
              <Approach />
            </ActiveSection>
          </section>
        </Element>

        {/* Section: Projects */}
        <Element name="section-projects">
          <section className="new-section projects" id="section-projects" data-aos="fade-in">
            <ActiveSection index="3" refToMenu={menuRef}>
              <MikesProjects />
            </ActiveSection>
          </section>
        </Element>

        {/* Section: Contact */}
        <Element name="section-contact">
          <section className="new-section contact" id="section-contact" data-aos="fade-in">
            <ActiveSection index="4" refToMenu={menuRef}>
              <ContactMike />
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
    </section>
  );
};

export default Home;
