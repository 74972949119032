import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ProjectsMenu() {
  const navigate = useNavigate();

  const handleMenuClick = (e, path) => {
    e.preventDefault();
    document.body.classList.add('disable-transitions');

    setTimeout(() => {
      navigate(path);
    }, 0);

  };

  return (
    <nav className="projects-menu">
      <h4>Projects</h4>
      <ul>
        <li><Link to="/aa" onClick={(e) => handleMenuClick(e, '/aa')}><i></i>Anglo American</Link></li>
        <li><Link to="/umg" onClick={(e) => handleMenuClick(e, '/umg')}><i></i>Universal Music</Link></li>
        <li><Link to="/turner" onClick={(e) => handleMenuClick(e, '/turner')}><i></i>Turner</Link></li>
        <li><Link to="/bbc" onClick={(e) => handleMenuClick(e, '/bbc')}><i></i>BBC Worldwide</Link></li>
        <li><Link to="/monum" onClick={(e) => handleMenuClick(e, '/monum')}><i></i>Monum</Link></li>
        <li><Link to="/setanta" onClick={(e) => handleMenuClick(e, '/setanta')}><i></i>Setanta</Link></li>
        <li><Link to="/ibm" onClick={(e) => handleMenuClick(e, '/ibm')}><i></i>IBM</Link></li>
        <li><Link to="/private" onClick={(e) => handleMenuClick(e, '/private')}><i></i>Private Jobs</Link></li>
      </ul>
    </nav>
  );
}

export default ProjectsMenu;
