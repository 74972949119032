import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import ActiveSection from '../../scripts/active-section';
import NavUnitTesting from '../header/NavUnitTesting';

// Images
import RunningTests from '../../images/interviews/running-tests.png';
import TestCounter from '../../images/interviews/test-counter.png';
import TestFile from '../../images/interviews/test-file.png';
import TestAuthService from '../../images/interviews/test-auth-service.png';
import TestAuthTestFile from '../../images/interviews/test-auth-test-file.png';
import TestDom from '../../images/interviews/test-dom.png';
import TestAsync from '../../images/interviews/test-async.png';
import UnitTestReproduce from '../../images/interviews/unti-test-reproduce.png';
import UnitTestAgainOne from '../../images/interviews/unit-test-again-one.png';
import UnitTestAgainTwo from '../../images/interviews/unit-test-again-two.png';
import ApiService from '../../images/interviews/api-service.png';
import ApiUnitTestOne from '../../images/interviews/api-unit-test-one.png';
import ApiUnitTestTwo from '../../images/interviews/api-unit-test-two.png';
import DebugOne from '../../images/interviews/debug-one.png';
import DebugTwo from '../../images/interviews/debug-two.png';
import DebugThree from '../../images/interviews/debug-three.png';
import SolutionOne from '../../images/interviews/solution-one.png';
import SolutionTwo from '../../images/interviews/solution-two.png';
import SolutionThree from '../../images/interviews/solution-three.png';
import UnitTestFix from '../../images/interviews/unit-test-fix.png';

const UnitTesting = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <NavUnitTesting ref={menuRef} />
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element className='element'>
          <section id="section-1" className='content-section flex-group flex-center'>
            <ActiveSection index="1" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Unit Testing in Angular (TDD Approach)</h2>
                <h3>What</h3>
                <p>
                  Unit testing is the process of testing small, isolated parts of an 
                  application to ensure they work as expected. Angular supports unit 
                  testing with Jasmine (test framework) and Karma (test runner).
                </p>
                <h3>Why</h3>
                <ul className="text-align-left margin-top-small">
                  <li>Ensures Code Quality – Catches bugs early.</li>
                  <li>Encourages Modular Design – Code is structured in a testable way.</li>
                  <li>Supports Refactoring – Allows developers to modify code without breaking functionality.</li>
                  <li>Improves Maintainability – Tests act as documentation for the future.</li>
                </ul>
                <h3>How</h3>
                <p>
                  <strong>(ng test)</strong> runs all unit tests in real-time. Whereas <strong>(ng test --coverage)</strong> runs 
                  tests & generates a code coverage report.
                </p>
                <img className="mw50 margin-top-med" src={RunningTests} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-2" className='content-section flex-group flex-center'>
            <ActiveSection index="2" refToMenu={menuRef}>
              <section className='script text-align-left flex-group flex-center flex-column'>
                <h2>Writing a Basic Unit Test (Component Example)</h2>
                <h3>Example: Counter Component</h3>
                <p>
                  We have a simple CounterComponent that increments a number. <strong><br/>
                  it() is a function used to define a test case.</strong>
                </p>
                <h3>Component & Test spec</h3>
                <div className="flex-group flex-start flex-nowrap">
                  <img className="mw40 margin-top-med" style={{height: "26rem"}} src={TestCounter} />
                  <img className="mw45 margin-top-med" src={TestFile} />
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-3" className='content-section flex-group flex-center'>
            <ActiveSection index="3" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Testing Services (Mocking Dependencies with Jasmine Spies)</h2>
                <p>When testing services, we mock dependencies to avoid real API calls.</p>
                <h3>AuthService & Test File (auth.service.spec.ts)</h3>
                <div className="flex-group flex-start flex-nowrap">
                  <img className="mw50 margin-top-med" style={{height: "16rem"}} src={TestAuthService} />
                  <img className="mw50 margin-top-med" src={TestAuthTestFile} />
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-4" className='content-section flex-group flex-center'>
            <ActiveSection index="4" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Testing DOM Interactions (DebugElement & Querying Elements)</h2>
                <p>When testing components, we often need to verify UI changes.</p>
                <h3>Example: Checking Button Click Behavior</h3>
                <img className="mw50 margin-top-med" src={TestDom} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-5" className='content-section flex-group flex-center'>
            <ActiveSection index="5" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 5: Handling Async Code (Testing HTTP Calls & Timers)</h2>
                <p>For async operations like HTTP requests, we use FakeAsync & Tick.</p>
                <h3>Example: Simulating API Delay</h3>
                <img className="mw50 margin-top-med" src={TestAsync} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-6" className='content-section flex-group flex-center'>
            <ActiveSection index="6" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Final Thoughts</h2>
                <h3>Next Steps & Final Thoughts</h3>
                <ul className="text-align-left margin-top-med">
                  <li>Mocking HTTP Requests – Using HttpTestingController to test API calls.</li>
                  <li>Testing Directives & Pipes – Ensuring custom directives and pipes work correctly.</li>
                  <li>Improving Code Coverage – Running <strong>ng test --code-coverage</strong> to check test completeness.</li>
                  <li>Write small, focused test cases.</li>
                  <li>Use spies for mocking services.</li>
                  <li>Utilize FakeAsync for async operations.</li>
                  <li>Keep tests independent and isolated.</li>
                </ul>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-7" className='content-section flex-group flex-center'>
            <ActiveSection index="7" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Describe how solved an issue with a User Search?</h2>
                <p>
                  In one of my projects, we had a search feature where users could filter a 
                  list dynamically. A user reported that the search wasn’t working as 
                  expected—sometimes it wouldn’t return the correct results or wouldn’t 
                  update properly as they typed. I used unit testing to identify and solve 
                  the issue. Here’s how I approached it.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-8" className='content-section flex-group flex-center'>
            <ActiveSection index="8" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 1: Identify the problem</h2>
                <p>
                  First, I analyzed the possible causes of the issue. I suspected two main problems:<br/>
                  1️⃣ Case Sensitivity: Searching "apple" didn't match "Apple".<br/>
                  2️⃣ Debounce Timing Issue: The search results updated inconsistently due to rapid user input.<br/>
                  To verify this, I wrote unit tests for our search logic.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-9" className='content-section flex-group flex-center'>
            <ActiveSection index="9" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 2: Writing Unit Tests to Reproduce the Issue</h2>
                <p>
                  I started with the search service, which was responsible for 
                  filtering results. Here’s an example of a (test case function) I wrote:
                </p>
                <img className="margin-top-med mw50" src={UnitTestReproduce} />
                <p>
                  🛠️ This test confirmed the issue—the search was case-sensitive. 
                  I fixed it by converting everything to lowercase.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-10" className='content-section flex-group flex-center'>
            <ActiveSection index="10" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 3: Fixing the Issue & Testing Again</h2>
                <p>
                  Next, I addressed the debounce timing issue. Users typed quickly, 
                  causing unnecessary filtering calls. I modified the component 
                  to include debounce timing:
                </p>
                <h3>Adding debounceTime(300) was the fix here</h3>
                <img className="margin-top-med mw50" src={UnitTestAgainOne} />
                <span className="margin-top-med">
                  Then, I wrote unit tests to ensure the debounce delay was working correctly:
                </span>
                <h3>This test uses fakeAsync() & tick() to simulate the delay. </h3>
                <img className="margin-top-med mw50" src={UnitTestAgainTwo} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-11" className='content-section flex-group flex-center'>
            <ActiveSection index="11" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 4: Confirming the Fix with Unit Tests</h2>
                <p>
                  After implementing these fixes, my unit tests confirmed that:
                </p>
                <ul className="text-align-left margin-top-med">
                  <li>The search was now case-insensitive.</li>
                  <li>Search results updated correctly after a debounce delay.</li>
                  <li>The bug was fully resolved and wouldn't break again.</li>
                </ul>
                <p>
                  This not only fixed the issue but also improved performance by 
                  preventing unnecessary filtering calls. The team was happy, 
                  and the users got a smoother search experience.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-12" className='content-section flex-group flex-center'>
            <ActiveSection index="12" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Final Takeaway (Why This Matters in TDD)</h2>
                <p>
                  This experience reinforced the power of unit testing for me. By writing tests first, I was able to:
                </p>
                <ul className="text-align-left margin-top-med">
                  <li>Identify and reproduce the issue quickly.</li>
                  <li>Implement a reliable, maintainable fix.</li>
                  <li>Prevent regressions, ensuring future changes wouldn’t break search functionality.</li>
                </ul>
                <p>
                  This was a great example of how TDD helps build reliable, user-friendly applications.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-13" className='content-section flex-group flex-center'>
            <ActiveSection index="13" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>API Service (search.service.ts</h2>
                <p>
                  This service makes an HTTP request to fetch search results:
                </p>
                <img className="margin-top-med mw50" src={ApiService} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-14" className='content-section flex-group flex-center'>
            <ActiveSection index="14" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Writing Unit Tests for API Calls (search.service.spec.ts)</h2>
                <ul className="text-align-left margin-top-med">
                  <li>Use HttpClientTestingModule to provide a mock HTTP client.</li>
                  <li>Use HttpClientTestingModule to provide a mock HTTP client.</li>
                </ul>
                <div className="flex-group flex-start flex-nowrap">
                  <img className="margin-top-med mw50" src={ApiUnitTestOne} />
                  <img className="margin-top-med mw50" src={ApiUnitTestTwo} />
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-15" className='content-section flex-group flex-center'>
            <ActiveSection index="15" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Explanation of the Code</h2>
                <h3>Import (HttpTestingController)</h3>
                <ul className="text-align-left margin-top-med">
                  <li>Intercepts HTTP requests so we can control responses.</li>
                  <li>Prevents actual API calls from being made.</li>
                </ul>
                <h3>Simulate the backend response with req.flush(data)</h3>
                <ul className="text-align-left margin-top-med">
                  <li>Sends a mock response to the test request.</li>
                  <li>Simulates API responses without making a real request.</li>
                </ul>
                <h3>Error Handling Test</h3>
                <ul className="text-align-left margin-top-med">
                  <li>Simulates a 500 server error to check if our service can handle it properly.</li>
                </ul>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-16" className='content-section flex-group flex-center'>
            <ActiveSection index="16" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Final Answer for the Interview</h2>
                <p>
                  To test API calls in Angular, I use HttpClientTestingModule and 
                  HttpTestingController. This allows me to mock HTTP requests and 
                  control responses without making actual API calls. I write test 
                  cases to verify successful responses, handle empty results, and 
                  check for error handling. This approach ensures our application 
                  correctly interacts with APIs while keeping tests fast and reliable.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-17" className='content-section flex-group flex-center'>
            <ActiveSection index="17" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Summary (Key Takeaways)</h2>
                <ul className="text-align-left margin-top-med">
                  <li>Mock API calls using HttpTestingController to intercept requests.</li>
                  <li>Use req.flush(data) to simulate API responses.</li>
                  <li>Test success, empty responses, and errors to cover all cases.</li>
                  <li>Prevent real HTTP requests from slowing down and affecting tests.</li>
                </ul>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-18" className='content-section flex-group flex-center'>
            <ActiveSection index="18" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>UI Troubleshooting Scenario: Search Box Issue</h2>
                <p>
                  A trader reports that the search box in the GTT system is not 
                  returning results as expected. The issue is intermittent, and 
                  some users claim it works while others say it doesn't.
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-19" className='content-section flex-group flex-center'>
            <ActiveSection index="19" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 1: Gather Information</h2>
                <p>Start by asking relevant questions:</p>
                <div className="mw40 margin-top-med">
                  <p>
                    <strong>1. Does the issue occur for all users or specific users?</strong><br/>
                    <span>
                      If only some users are affected, it could be role-based 
                      permissions, cached data, or browser-specific issues.<br/>
                    </span>
                  </p>
                  <p>
                    <strong>2. Is there an error message or console log entry?</strong><br/>
                    <span>
                      Open DevTools (F12) and check the console/log network requests.<br/>
                    </span>
                  </p>
                  <p>
                    <strong>3. What were the steps taken before the issue occurred?</strong><br/>
                    <span>
                      Find out if users are filtering incorrectly, using special characters, 
                      or expecting case-insensitive results.<br/>
                    </span>
                  </p>
                  <p>
                    <strong>4. Does the issue occur across different browsers or devices?</strong><br/>
                    <span>
                      If it’s happening only in one browser, it could be a compatibility issue 
                      (e.g., Internet Explorer handling differently from Chrome).
                    </span>
                  </p>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-20" className='content-section flex-group flex-center'>
            <ActiveSection index="20" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 2: Debugging & Identifying Root Cause</h2>
                <div className="flex-group flex-start flex-nowrap">
                  <div className="margin-right-med">
                    <h3 className="margin-bottom-small">Check Network Requests</h3>
                    <strong>1. Open the Network tab in Chrome DevTools (F12 → Network).</strong><br/>
                    <strong>2. Look for failed API requests when searching.</strong><br/>
                    <h3>Common Issues</h3><br/>
                    <strong>1. 404 (Not Found): The API endpoint might be incorrect.</strong><br/>
                    <strong>2. 403 (Forbidden): User might not have permissions.</strong><br/>
                    <strong>3. 500 (Internal Server Error): Backend issue.</strong><br/>
                    <strong>4. Slow response: API may be timing out.</strong>
                    <h3 className="margin-bottom-small">Inspect Angular Component & Data Binding</h3>
                    <strong>Check if the search query is being updated correctly:</strong><br/>
                    <img className="mw40 margin-top-med margin-bottom-small" src={DebugOne} /><br/>
                    <strong>Debug using::</strong><br/>
                    <img className="mw40" src={DebugTwo} />
                  </div>
                  <div className="mw40">
                    <h3 className="margin-bottom-small">Verify API Integration</h3>
                    <strong>1. If the search query is sent to an API, test it manually:</strong><br/>
                    <strong>2. Use Postman or cURL to send the same request the UI is making.</strong><br/>
                    <strong>3. Confirm the API is returning expected results.</strong><br/>
                    <strong>Example Postman request:</strong><br/>
                    <img className="mw40" src={DebugThree} /><br/>
                    <h3>Check for UI Issues (CSS, HTML)</h3><br/>
                    <strong>1. Sometimes, the issue isn’t with functionality but UI rendering.</strong><br/>
                    <strong>2. Hidden search results? Check display: none or visibility settings.</strong><br/>
                    <strong>3. Z-index conflicts? Another UI element may be overlapping the search results.</strong><br/>
                    <strong>4. Misalignment on mobile? Inspect using responsive mode (F12 → Toggle Device Toolbar).</strong><br/>
                    <h3>Check for JavaScript Errors</h3><br/>
                    <strong>1. Open DevTools → Console (F12 → Console).</strong><br/>
                    <strong>2. Look for TypeError, ReferenceError, or SyntaxError that might be causing the search function to break.</strong><br/>
                  </div>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-21" className='content-section flex-group flex-center'>
            <ActiveSection index="21" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 3: Solution Implementation</h2>
                <strong>If API calls are failing, report to backend or handle errors in UI:</strong>
                <h3>Create a callback function</h3>
                <img className="mw40 margin-top-med" src={SolutionOne} /><br/>
                <h3>If data binding is failing, check for missing updates:</h3>
                <img className="mw40 margin-top-med" src={SolutionTwo} /><br/>
                <h3>If it’s a UI issue, fix CSS visibility:</h3>
                <img className="mw40 margin-top-med" src={SolutionThree} /><br/>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-22" className='content-section flex-group flex-center'>
            <ActiveSection index="22" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Step 4: Prevent Future Issues</h2>
                <h3>Write Unit Tests:</h3>
                <img className="mw40 margin-top-med" src={UnitTestFix} /><br/>
                <strong>1. Monitor logs in production using tools like Sentry or New Relic.</strong>
                <strong>2. Improve error handling (e.g., show a message if the search fails).</strong>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-23" className='content-section flex-group flex-center'>
            <ActiveSection index="23" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Summary</h2>
                <strong>1. Identify the issue (user feedback, console logs, network requests).</strong>
                <strong>2. Reproduce the problem (browser/devices, API calls, UI interactions).</strong>
                <strong>3. Debug step by step (API response, UI visibility, JavaScript errors).</strong>
                <strong>4. Implement a fix (data binding, error handling, UI adjustments).</strong>
                <strong>5. Prevent future issues (testing, monitoring, logging).</strong>
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default UnitTesting;