import React, { useState } from 'react';
import PixelHeadsLogo from '../../images/pixelheads-logo.svg';
import Loading from '../../images/loading.gif';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInvalid, setIsInvalid] = useState({
    name: false,
    email: false,
    message: false,
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // New state to track form submission

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const invalidFields = {
      name: !formData.name.trim(),
      email: !formData.email.trim(),
      message: !formData.message.trim(),
    };

    setIsInvalid(invalidFields);

    if (Object.values(invalidFields).some((field) => field)) {
      setResponseMessage('Please fill required fields (*)');
      return;
    }

    setIsSubmitting(true);

    let functionUrl;

    if (window.location.hostname === 'localhost') {
      functionUrl = 'http://localhost:8888/.netlify/functions/contact';
      // console.log('contact: you are using localhost');
    } else {
      functionUrl = 'https://pixelheads-server.netlify.app/.netlify/functions/contact';
      // console.log('contact: you are using prod');
    }

    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const text = await response.text();
      let result;

      try {
        result = JSON.parse(text);
      } catch {
        throw new Error('Invalid response from server');
      }

      if (!response.ok) {
        throw new Error(result.error || 'An error occurred.');
      }

      setResponseMessage(result.message);
      setIsSubmitted(true); // Hide form and show success message
    } catch (error) {
      console.error('Submission error:', error);
      setResponseMessage(error.message || 'Error submitting form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setFormData({ name: '', email: '', phone: '', message: '' });
    setResponseMessage('');
  };

  return (
    <div className="flex-group flex-center flex-column">
      <h1>Contact</h1>
      <img src={PixelHeadsLogo} alt="PixelHeads Logo" />
      <p className="mw400 margin-top-med">
        Interested in collaborating or need a Senior UX/UI Designer/Front-end Developer for your next project?
        Feel free to reach out, I'd love to hear from you!
      </p>
      {isSubmitted ? ( // Check if the form is submitted
        <div className="margin-top-small">
          <h2>Thank you!</h2>
          <p>
            Your message has been successfully submitted.<br/>
            We’ll get back to you soon.
          </p>
        </div>
      ) : (
        <form noValidate onSubmit={handleSubmit} className="contact-form margin-top-small">
          {responseMessage && <p className="err-msg">{responseMessage}</p>}
          <div className="flex-group flex-start flex-nowrap flex-align-center">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {isInvalid.name && <div className="required"> *</div>}
          </div>
          <div className="flex-group flex-start flex-nowrap flex-align-center">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {isInvalid.email && <div className="required"> *</div>}
          </div>
          <div>
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="flex-group flex-start flex-nowrap">
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {isInvalid.message && <div className="required"> *</div>}
          </div>
          <div className="flex-group">
            <button className="btn-reset" type="button" onClick={handleReset} disabled={isSubmitting}>
              Reset
            </button>
            <button className="btn-submit position-relative" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending' : 'Send'}
              {isSubmitting && <img className="loading" src={Loading} alt="" />}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default Contact;
