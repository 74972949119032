import React, { useState, useEffect } from 'react';
import ReactBody from 'react-body';
import { animateScroll as scroll, scroller } from 'react-scroll';
import AOS from "aos";
import "aos/dist/aos.css";

// image
import BannerImage from '../../images/clients/pages/ibm/banner.png';
import Charting01 from './../../images/clients/pages/ibm/charting/example01.png';
import Charting02 from './../../images/clients/pages/ibm/charting/example02.png';
import Charting03 from './../../images/clients/pages/ibm/charting/example03.png';
import Charting04 from './../../images/clients/pages/ibm/charting/example04.png';
import Charting05 from './../../images/clients/pages/ibm/charting/example05.png';

import MCM01 from './../../images/clients/pages/ibm/cloud-manager/notifications/example01.png';
import MCM02 from './../../images/clients/pages/ibm/cloud-manager/notifications/example02.png';
import MCM03 from './../../images/clients/pages/ibm/cloud-manager/notifications/example03.png';
import MCM04 from './../../images/clients/pages/ibm/cloud-manager/notifications/example04.png';
import MCM05 from './../../images/clients/pages/ibm/cloud-manager/notifications/example05.png';

import CloudPak01 from './../../images/clients/pages/ibm/cloud-pak/example01.png';
import CloudPak02 from './../../images/clients/pages/ibm/cloud-pak/example02.png';
import CloudPak03 from './../../images/clients/pages/ibm/cloud-pak/example03.png';
import CloudPak04 from './../../images/clients/pages/ibm/cloud-pak/example04.png';
import CloudPak05 from './../../images/clients/pages/ibm/cloud-pak/example05.png';


// includes
import ProjectsNav from '../menus/ProjectsNav';
import ProjectsMenu from '../menus/ProjectsMenu';

function IBM() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
      // Set a timeout to trigger the fade-in effect
      setTimeout(() => {
      setIsVisible(true); // Trigger the fade-in after the page has loaded
      }, 0); // You can delay this a bit if needed, but 0 will work for an immediate fade-in
  }, []);

  useEffect(() => {
      AOS.init({
          duration: 1000, // Default animation duration
          once: true,     // Whether animation should happen only once
      });
      AOS.refresh(); // Refresh AOS to re-apply animations if content changes dynamically
  }, []);

  scroll.scrollToTop();

  const scrollToSection = (sectionId, index, event) => {
      event.preventDefault();  // Prevent the default anchor scroll behavior
      scroller.scrollTo(sectionId, {
          duration: 200,   // Add duration for smooth scrolling
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50,     // Adjust based on your needs
      });
  };

  return (
    <div className={`page-fade-in ${isVisible ? "visible" : ""}`}>
      <ReactBody className="ibm projects" />
      <ProjectsNav />
      <div className="banner ibm-active">
        <ProjectsMenu />
        <header className="projects-header">
          <h1>IBM</h1>
          <ul>
            <li>
              <h3>Client</h3>
              <p>IBM</p>
            </li>
            <li>
              <h3>Platform</h3>
              <p>Desktop & Mobile</p>
            </li>
            <li>
              <h3>Services</h3>
              <p>UX UI Product Design<br />
                Cloud Computing SaaS
              </p>
            </li>
            <li>
              <h3>When</h3>
              <p>2012 - 2013</p>
            </li>
          </ul>
        </header>
        <img src={BannerImage} alt="" data-aos="fade-in" data-aos-duration="1000" />
      </div>
      <nav className="mini-menu flex-group flex-column flex-nowrap flex-end">
          <a
              href='#section-one'
              onClick={() => scrollToSection('section-one', 1)}
          >
              <span className='flex-group'>
                  <span className='menu-name'>Charting</span>
                  <span className='material-icons'>area_chart</span>
              </span>
          </a>
          <a
              href='#section-two'
              onClick={() => scrollToSection('section-two', 2)}
          >
              <span className='flex-group'>
                  <span className='menu-name'>IBM Multi-Cloud</span>
                  <span className='material-icons'>filter_drama</span>
              </span>
          </a>
          <a
              href='#section-three'
              onClick={() => scrollToSection('section-three', 3)}
          >
              <span className='flex-group'>
                  <span className='menu-name'>IBM Cloud Pak</span>
                  <span className='material-icons'>inventory_2</span>
              </span>
          </a>
      </nav>
      <div className="container-fluid col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="row" id="section-one">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h2>Overview</h2>
            <p className='mw800'>
              Designed and optimised user interfaces for IBM’s cloud-based SaaS, collaborating with product
              managers and engineers to enhance usability and accessibility.
              Conducted user research and usability testing, improving task completion rates by 30% through
              refined user journeys for cloud management.
              Created wireframes, prototypes, and high-fidelity mock-ups in Figma and Adobe XD, ensuring
              adherence to IBM’s design standards.
              Supported Agile workflows, contributing to sprint planning, design reviews, and iterative testing to
              enhance the product experience.
            </p>
          </div>
        </div>

        <div className="row">
          <section className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h3>Charting & Colour Palette</h3>
                <img src={Charting01} className="w-100" alt="" />
                <img src={Charting02} className="w-100" alt="" />
                <img src={Charting03} className="w-100" alt="" />
                <img src={Charting04} className="w-100" alt="" />
                <img src={Charting05} className="w-100" alt="" />
          </section>
        </div>

        <div className="row" id="section-two">
          <section className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h3>IBM Multi-Cloud Manager (Notifications)</h3>
              <p className='w-30'>
                IBM Multi-Cloud Manager was a Kubernetes-based management solution that 
                enabled enterprises to securely deploy, monitor, and manage applications 
                across multiple cloud environments, including public, private, and hybrid 
                clouds. Designed to provide visibility, governance, and automation, it 
                streamlined operations by offering a unified control plane for clusters, 
                policies, and security across diverse infrastructures. As a UX Product 
                Designer on this project, the focus was on crafting intuitive interfaces 
                that simplified complex cloud orchestration tasks, ensuring seamless 
                user experiences for DevOps teams managing multi-cloud deployments at scale.
              </p>
              <img src={MCM01} className="w-100" alt="" />
              <img src={MCM02} className="w-100" alt="" />
              <img src={MCM03} className="w-100" alt="" />
              <img src={MCM04} className="w-100" alt="" />
              <img src={MCM05} className="w-100" alt="" />
          </section>
        </div>

        <div className="row" id="section-three">
          <section className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h3>IBM Cloud Pak</h3>
              <p className='w-30'>
                IBM Cloud Pak was a containerized software solution designed to help enterprises 
                build, deploy, and manage AI-powered applications across hybrid cloud environments. 
                Built on Red Hat OpenShift, it provided integrated capabilities for automation, 
                data management, and security, enabling businesses to modernise their IT 
                infrastructure with greater flexibility and efficiency. As a UX Product Designer 
                on this project, the focus was on creating seamless, user-centric interfaces that 
                enhanced usability, streamlined workflows, and empowered teams to harness the full 
                potential of cloud-native technologies.
              </p>
              <img src={CloudPak01} className="w-100" alt="" />
              <img src={CloudPak02} className="w-100" alt="" />
              <img src={CloudPak03} className="w-100" alt="" />
              <img src={CloudPak04} className="w-100" alt="" />
              <img src={CloudPak05} className="w-100" alt="" />
          </section>
        </div>
      </div>
    </div>
  );
}

export default IBM;
