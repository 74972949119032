import React, { useState, useEffect } from 'react';

const NavProjectWalkthrough = () => {
  // Retrieve last active section from localStorage (default to "1")
  const [isActiveIndex, setIsActiveIndex] = useState(localStorage.getItem('activeAngularSection') || "1");

  const toggleActiveClass = (index) => {
    setIsActiveIndex(index);
    localStorage.setItem('activeAngularSection', index); // Store in localStorage
    document.getElementById(`section-${index}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  // Function to observe sections on scroll
  useEffect(() => {
    const sections = document.querySelectorAll('.content-section'); // Target all sections

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id.replace('section-', '');
            setIsActiveIndex(sectionId);
            localStorage.setItem('activeAngularSection', sectionId); // Store new section
          }
        });
      },
      { threshold: 0.6 } // Trigger when 60% of section is visible
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  // Scroll to the last active section when the component mounts
  useEffect(() => {
    const savedIndex = localStorage.getItem('activeAngularSection');
    if (savedIndex) {
      document.getElementById(`section-${savedIndex}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="interviews-nav-section">
      <nav className="interviews-nav flex-group flex-column mw10">
        <h4>Project Walkthrough</h4>
        <a 
          className={`${isActiveIndex === "1" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("1"); }} 
          href="#section-1"
        >
          Setting the stage
        </a>
        <a 
          className={`${isActiveIndex === "2" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("2"); }} 
          href="#section-2"
        >
          Discovery Phase
        </a>
        <a 
          className={`${isActiveIndex === "3" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("3"); }} 
          href="#section-3"
        >
          Design & Prototyping
        </a>
        <a 
          className={`${isActiveIndex === "4" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("4"); }} 
          href="#section-4"
        >
          Validation & Testing
        </a>
        <a 
          className={`${isActiveIndex === "5" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("5"); }} 
          href="#section-5"
        >
          Handoff & Implementation
        </a>
        <a 
          className={`${isActiveIndex === "6" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("6"); }} 
          href="#section-6"
        >
          Outcome & Impact
        </a>
        <a 
          className={`${isActiveIndex === "7" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("7"); }} 
          href="#section-7"
        >
          Reflection & Takeaways
        </a>
      </nav>
    </div>
  );
};

export default NavProjectWalkthrough;
