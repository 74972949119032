import React, { useState, useEffect } from 'react';

const NavUnitTesting = () => {
  // Retrieve last active section from localStorage (default to "1")
  const [isActiveIndex, setIsActiveIndex] = useState(localStorage.getItem('activeUnitTestSection') || "1");

  const toggleActiveClass = (index) => {
    setIsActiveIndex(index);
    localStorage.setItem('activeUnitTestSection', index); // Store in localStorage
    document.getElementById(`section-${index}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  // Function to observe sections on scroll
  useEffect(() => {
    const sections = document.querySelectorAll('.content-section'); // Target all sections

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id.replace('section-', '');
            setIsActiveIndex(sectionId);
            localStorage.setItem('activeUnitTestSection', sectionId); // Store new section
          }
        });
      },
      { threshold: 0.6 } // Trigger when 60% of section is visible
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  // Scroll to the last active section when the component mounts
  useEffect(() => {
    const savedIndex = localStorage.getItem('activeUnitTestSection');
    if (savedIndex) {
      document.getElementById(`section-${savedIndex}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="interviews-nav-section">
      <nav className="interviews-nav flex-group flex-column mw10">
        <h4>Unit Testing</h4>
        <a 
          className={`${isActiveIndex === "1" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("1"); }} 
          href="#section-1"
        >
          TDD Approach
        </a>
        <a 
          className={`${isActiveIndex === "2" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("2"); }} 
          href="#section-2"
        >
          Writing a Basic Unit Test
        </a>
        <a 
          className={`${isActiveIndex === "3" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("3"); }} 
          href="#section-3"
        >
          Testing Services
        </a>
        <a 
          className={`${isActiveIndex === "4" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("4"); }} 
          href="#section-4"
        >
          Testing DOM Interactions
        </a>
        <a 
          className={`${isActiveIndex === "5" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("5"); }} 
          href="#section-5"
        >
          Handling Async Code
        </a>
        <a 
          className={`${isActiveIndex === "6" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("6"); }} 
          href="#section-6"
        >
          Final Thoughts
        </a>
        <h4 className="margin-top-large">Overcoming an issue</h4>
        <a 
          className={`${isActiveIndex === "7" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("7"); }} 
          href="#section-7"
        >
          User Search Issue
        </a>
        <a 
          className={`${isActiveIndex === "8" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("8"); }} 
          href="#section-8"
        >
          Step 1: Identify the problem
        </a>
        <a 
          className={`${isActiveIndex === "9" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("9"); }} 
          href="#section-9"
        >
          Step 2: Test to Reproduce Issue
        </a>
        <a 
          className={`${isActiveIndex === "10" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("10"); }} 
          href="#section-10"
        >
          Step 3: Fix Issue, Unit Test Again
        </a>
        <a 
          className={`${isActiveIndex === "11" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("11"); }} 
          href="#section-11"
        >
          Step 4: Confirm Fix with Unit Test
        </a>
        <a 
          className={`${isActiveIndex === "12" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("12"); }} 
          href="#section-12"
        >
          Final Takeaway
        </a>
        <h4 className="margin-top-large">Mock API Calls</h4>
        <a 
          className={`${isActiveIndex === "13" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("13"); }} 
          href="#section-13"
        >
          API Service
        </a>
        <a 
          className={`${isActiveIndex === "14" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("14"); }} 
          href="#section-14"
        >
          Unit Tests for API Calls
        </a>
        <a 
          className={`${isActiveIndex === "15" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("15"); }} 
          href="#section-15"
        >
          Explanation of the Code
        </a>
        <a 
          className={`${isActiveIndex === "16" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("16"); }} 
          href="#section-16"
        >
          Final Answer for Interview
        </a>
      </nav>
    </div>
  );
};

export default NavUnitTesting;
