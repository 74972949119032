import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import ActiveSection from '../../scripts/active-section';
import NavProjectWalkthrough from '../header/NavProjectWalkthrough';

const ProjectWalkthrough = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <NavProjectWalkthrough ref={menuRef} />
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element className='element'>
          <section id="section-1" className='content-section flex-group flex-center'>
            <ActiveSection index="1" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <div className='align-left'>
                  <h2>Setting the Stage: Project Context</h2>
                  <h3>Project Overview</h3>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Greenfield (built from scratch)</li>
                    <li>Project Name: StudioHub (Suite of 4 Applications)</li>
                    <li>Your Role: Sole UX Designer (branding, UX, UI, design system)</li>
                    <li>Team Collaboration: Worked with developers, BAs, stakeholders, and music labels.</li>
                  </ul>
                  <h3>Business Problem & Need</h3>
                  <ul className='align-left margin-top-small mw40'>
                    <li>UMG’s album production and distribution process was fragmented, leading to inefficiencies.</li>
                    <li>Labels and artists had no centralized tool to manage studio sessions, track metadata, and streamline album production.</li>
                    <li>There were false rights claims due to legacy systems, causing revenue loss.</li>
                  </ul>
                  <h3>Project Goal</h3>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Design a centralized platform that streamlines album production from studio recording to supply chain distribution across streaming services (Apple Music, Spotify, Amazon, Deezer).</li>
                    <li>Provide a collaborative and intuitive experience for music labels to manage contributors, tracks, and assets.</li>
                  </ul>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-2" className='content-section flex-group flex-center'>
            <ActiveSection index="2" refToMenu={menuRef}>
              <section className='script text-align-left flex-group flex-center flex-column'>
                <div className='align-left'>
                  <h2>Discovery Phase: UX Research & Ideation</h2>
                  <h3>Understanding the Users & Needs</h3>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Conducted workshops with music labels, producers, and internal teams to identify pain points.</li>
                    <li>Mapped user workflows from studio recording to digital distribution.</li>
                    <li>Created personas (e.g., studio engineers, A&R managers, label execs).</li>
                  </ul>
                  <h3>Sketching & Low-Fidelity Prototyping</h3>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Whiteboarding sessions with stakeholders to define initial structure.</li>
                    <li>Developed low-fidelity wireframes using Balsamiq/XD to explore key flows.</li>
                    <li>Conducted iterative feedback cycles with users (studio managers, artists).</li>
                  </ul>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-3" className='content-section flex-group flex-center'>
            <ActiveSection index="3" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <div className='align-left'>
                  <h2>Design & Prototyping</h2>
                  <h3 className='margin-bottom-small'>Defining the Information Architecture</h3>
                  <strong>Structured the 4-app suite with a clear IA to cover all production phases:</strong>
                  <ol className='align-left margin-top-small mw40'>
                    <li>StudioHub: Managing recording sessions & contributors.</li>
                    <li>Archive Workspace: Organizing and tracking digital assets.</li>
                    <li>RightsHub: Legal contracts and payments</li>
                    <li>Supply Chain App: Ensuring smooth delivery to streaming platforms.</li>
                  </ol>
                  <h3>User Flows & Wireframing</h3>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Created user flows for adding contributors, managing artist rights, tracking assets, and submitting to distribution.</li>
                    <li>Iteratively refined wireframes with user feedback.</li>
                  </ul>
                  <h3>Visual & UI Design</h3>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Designed branding, logo, and color scheme to reflect UMG’s premium identity.</li>
                    <li>Developed high-fidelity UI prototypes in Balsamiq and Adobe XD.</li>
                    <li>Established a design system to maintain consistency across the suite.</li>
                  </ul>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-4" className='content-section flex-group flex-center'>
            <ActiveSection index="4" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <div className='align-left'>
                  <h2>Validation & Testing</h2>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Interactive prototypes tested with internal users (studio engineers, label managers).</li>
                    <li>Conducted usability testing to refine navigation and interaction patterns.</li>
                    <li>Iterated on design based on user pain points (e.g., improved metadata input process).</li>
                  </ul>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-5" className='content-section flex-group flex-center'>
            <ActiveSection index="5" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <div className='align-left'>
                  <h2>Handoff & Implementation</h2>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Collaborated closely with developers to ensure design feasibility.</li>
                    <li>Provided detailed specs & prototypes for development teams.</li>
                    <li>Supported agile sprints, ensuring design consistency across build iterations.</li>
                  </ul>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-6" className='content-section flex-group flex-center'>
            <ActiveSection index="6" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <div className='align-left'>
                  <h2>Outcome & Impact</h2>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Increased efficiency in album production and distribution.</li>
                    <li>Reduced false rights claims by ensuring accurate metadata.</li>
                    <li>Standardized digital supply chain process, making it easier for labels to push albums to streaming platforms.</li>
                    <li>Positive user feedback from music labels on usability and intuitive workflows.</li>
                  </ul>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-7" className='content-section flex-group flex-center'>
            <ActiveSection index="7" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <div className='align-left'>
                  <h2>Reflection & Takeaways</h2>
                  <ul className='align-left margin-top-small mw40'>
                    <li>Challenges: Aligning with various stakeholders (labels, internal teams) and ensuring seamless data flows.</li>
                    <li>Learnings: Importance of early user testing and cross-functional collaboration.</li>
                    <li>Next Steps: Potential expansion of StudioHub to integrate AI-driven metadata validation.</li>
                  </ul>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default ProjectWalkthrough;