import React, { useState, useRef, useEffect } from 'react';

function AudioPlayer({ onToggleNotes }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  // Toggle the audio play/pause
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);

    if (onToggleNotes) {
      onToggleNotes();
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    audioElement.volume = 0.1;

    // Start playing audio on page load
    // const playAudio = async () => {
    //   try {
    //     await audioElement.play(); 
    //     setIsPlaying(true);
    //   } catch (error) {
    //     console.error("Autoplay failed: ", error);
    //   }
    // };

    // playAudio(); 

    return () => {
      audioElement.pause();
    };
  }, []);

  return (
    <>
      <audio ref={audioRef} loop>
        <source src="/audio/take-five-dave-drubeck.mp3" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>

      <button
        onClick={togglePlayPause}
        className={`toggle-music material-icons ${isPlaying ? 'playing' : 'paused'}`}
      >
        {isPlaying ? 'volume_off' : 'volume_up'}
      </button>
    </>
  );
}

export default AudioPlayer;
