import React from "react";
import { Outlet } from "react-router-dom";
import HeaderInterviews from "../header/HeaderInterviews";

export default function Layout() {
  return (
    <div className="interviews-content">
      <header className="main-header interviews-header flex-group flex-start flex-align-center">
        <HeaderInterviews />
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  );
}
