import React, { useState, useEffect } from 'react';
import ReactBody from 'react-body';
import { animateScroll as scroll, scroller } from 'react-scroll';
import AOS from "aos";
import "aos/dist/aos.css";

// images
import BannerImage from '../../images/clients/pages/aa/banner.png';
import DesignLanguage from '../../images/clients/pages/aa/dl.png';
import Comps01 from './../../images/clients/pages/aa/example01.png';
import Comps02 from './../../images/clients/pages/aa/example02.png';
import Comps03 from './../../images/clients/pages/aa/example03.png';
import Comps04 from './../../images/clients/pages/aa/example04.png';
import Comps05 from './../../images/clients/pages/aa/example05.png';

import ValuTrax01 from './../../images/clients/pages/aa/valutrax/example01.png';
import ValuTrax02 from './../../images/clients/pages/aa/valutrax/example02.png';
import ValuTrax03 from './../../images/clients/pages/aa/valutrax/example03.png';
import ValuTrax04 from './../../images/clients/pages/aa/valutrax/example04.png';
import ValuTrax05 from './../../images/clients/pages/aa/valutrax/example05.png';
import ValuTrax06 from './../../images/clients/pages/aa/valutrax/example06.png';
import ValuTrax07 from './../../images/clients/pages/aa/valutrax/example07.png';

// includes
import ProjectsNav from '../menus/ProjectsNav';
import ProjectsMenu from '../menus/ProjectsMenu';

function AA() {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        // Set a timeout to trigger the fade-in effect
        setTimeout(() => {
        setIsVisible(true); // Trigger the fade-in after the page has loaded
        }, 0); // You can delay this a bit if needed, but 0 will work for an immediate fade-in
    }, []);

    
    useEffect(() => {
        AOS.init({
            duration: 1000, // Default animation duration
            once: true,     // Whether animation should happen only once
        });
        AOS.refresh(); // Refresh AOS to re-apply animations if content changes dynamically
    }, []);

    scroll.scrollToTop();
    
    const scrollToSection = (sectionId, index, event) => {
        event.preventDefault();  // Prevent the default anchor scroll behavior
        scroller.scrollTo(sectionId, {
            duration: 200,   // Add duration for smooth scrolling
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -50,     // Adjust based on your needs
        });
    };

    return (
        <div className={`page-fade-in ${isVisible ? "visible" : ""}`}>
            <ReactBody className="aa projects" />
            <ProjectsNav />
            <div className="banner aa-active">
                <ProjectsMenu />
                <nav className="downloads-menu">
                    <h3 className="white align-right border-none">Related</h3>
                    <ul>
                        <li>
                            <a href="https://pixelheads-dl.netlify.app/" title="Will open in a New Window" rel="noopener noreferrer" target="_blank">Design Language</a>
                        </li>
                    </ul>
                </nav>
                <header className="projects-header">
                    <h1>Anglo American</h1>
                    <ul className="umg-overview">
                        <li>
                            <h3>Client</h3>
                            <p>Anglo American</p>
                        </li>
                        <li>
                            <h3>Platform</h3>
                            <p>Desktop & Mobile</p>
                        </li>
                        <li>
                            <h3>Services</h3>
                            <p>Contract UX,<br />Front-End Development</p>
                        </li>
                        <li>
                            <h3>When</h3>
                            <p>Feb 2020 - Present</p>
                        </li>
                    </ul>
                </header>
                <img src={BannerImage} alt="" data-aos="fade-in" data-aos-duration="1000" />
            </div>
            <nav className="mini-menu flex-group flex-column flex-nowrap flex-end">
                <a
                    href='#section-one'
                    onClick={(e) => {
                        scrollToSection('section-one', e);
                        setActiveClass(1);
                    }}
                >
                    <span className='flex-group'>
                        <span className='menu-name'>Design Language</span>
                        <span className='material-icons'>design_services</span>
                    </span>
                </a>
                <a
                    href='#section-two'
                    onClick={(e) => {
                        scrollToSection('section-two', e);
                        setActiveClass(2);
                    }}
                >
                    <span className='flex-group'>
                        <span className='menu-name'>Figma Component Library</span>
                        <span className='material-icons'>local_library</span>
                    </span>
                </a>
                <a
                    href='#section-three'
                    onClick={(e) => {
                        scrollToSection('section-three', e);
                        setActiveClass(3);
                    }}
                >
                    <span className='flex-group'>
                        <span className='menu-name'>Valutrax</span>
                        <span className='material-icons'>directions_boat</span>
                    </span>
                </a>
            </nav>
            <div className="container-fluid col-xs-12 col-sm-12 col-md-12 col-lg-12 umg-mobile">
                <div className="row" id="section-one">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="flex-group space-between flex-align-center">
                            <h2>Design Language</h2>
                        </div>
                        <p className='mw800'>
                                Angular Design Language (ADL) is a comprehensive design system I developed from scratch 
                                to empower frontend developers and UX/UI designers in creating consistent, scalable, 
                                and intuitive web applications. Focused on Angular, it streamlines the design and 
                                development process by providing a robust library of reusable components and standardised 
                                design patterns. ADL enhances collaboration by aligning teams around a unified design 
                                system, ensuring brand consistency, optimal user experience, and efficient development 
                                workflows. This system has been integral in supporting over 150 developers at Anglo 
                                American PLC, enabling the rapid creation of dynamic and high-quality trading platforms 
                                for global mining products.
                            </p>
                            <p>
                            <a
                                className="margin-top-small red flex-align-center"
                                href="https://pixelheads-dl.netlify.app/"
                                title="Will open in a New Window"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <span className="material-icons">insert_link</span>You can visit The Design Language here.
                            </a>
                            </p>
                        <img className="w-100" src={DesignLanguage} alt="" />
                    </div>
                </div>

                <div className="row" id="section-two">
                    <section className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h3>Figma Component Library</h3>
                        <p className='w-30'>
                            I created a brand and component library for in Figma. I then too these designs and re-created 
                            then in Angular along with all the documentation.
                        </p>
                        <img src={Comps01} className="w-100" alt="" />
                        <img src={Comps02} className="w-100" alt="" />
                        <img src={Comps03} className="w-100" alt="" />
                        <img src={Comps04} className="w-100" alt="" />
                        <img src={Comps05} className="w-100" alt="" />
                    </section>
                </div>

                <div className="row" id="section-three">
                    <section className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h3>Valutrax</h3>
                        <p className='w-30'>
                            ValuTrax is an innovative platform developed by Anglo American, designed to help 
                            organisations track and manage the value and impact of their investments and projects. 
                            I had the opportunity to contribute to the re-design of its user interface, 
                            focusing on enhancing the overall user experience with a more intuitive and visually 
                            appealing design. My role involved collaborating closely with stakeholders to 
                            understand the platform’s goals and users' needs, creating a seamless, modern UI 
                            that improves usability and accessibility while maintaining the brand's identity.
                        </p>
                        <img src={ValuTrax01} className="w-100" alt="" />
                        <img src={ValuTrax02} className="w-100" alt="" />
                        <img src={ValuTrax03} className="w-100" alt="" />
                        <img src={ValuTrax04} className="w-100" alt="" />
                        <img src={ValuTrax05} className="w-100" alt="" />
                        <img src={ValuTrax06} className="w-100" alt="" />
                        <img src={ValuTrax07} className="w-100" alt="" />
                    </section>
                </div>
            </div>
        </div>
    );
}

export default AA;
