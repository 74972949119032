import React, { useEffect, useState, useRef } from "react";
import LinkedIn from "../../images/social/linkedin.svg";
import FB from "../../images/social/fb.svg";
import GitHub from "../../images/social/github.svg";
import car1 from "../../images/home/car1.png";
import car2 from "../../images/home/car2.png";
import car3 from "../../images/home/car3.png";
import car4 from "../../images/home/car4.png";

import AngloLogo from "../../images/clients/logos/anglo.svg";
import BBCLogo from "../../images/clients/logos/bbc.svg";
import BrannLogo from "../../images/clients/logos/brann.svg";
import DiscLogo from "../../images/clients/logos/discovery.svg";
import IBMLogo from "../../images/clients/logos/ibm.svg";
import PfizerLogo from "../../images/clients/logos/pfizer.svg";
import RavensLogo from "../../images/clients/logos/ravensbourne.svg";
import TurnerLogo from "../../images/clients/logos/turner.svg";
import UniversalLogo from "../../images/clients/logos/universal.svg";

import PennantFlag from "../../images/home/pennant-flag.gif";

const CAR_IMAGES = [car1, car2, car3, car4];
const SCREEN_WIDTH = window.innerWidth;
const CAR_COUNT = 10; // Number of cars on the scene

const Landing = React.forwardRef((props, ref) => {
  const [quote, setQuote] = useState("");
  const [zoomToProject, setZoomToProject] = useState(null);
  const streetRef = ref || useRef(null); // Fallback to useRef if no ref is passed
  const [uniHovered, setUniHovered] = useState(false);
  const [brannHovered, setBrannHovered] = useState(false);
  const [pfizerHovered, setPfizerHovered] = useState(false);
  const [discHovered, setDiscHovered] = useState(false);
  const [bbcHovered, setBbcHovered] = useState(false);
  const [tunerHovered, setTurnerHovered] = useState(false);
  const [ibmHovered, setIbmHovered] = useState(false);
  const [umgHovered, setUmgHovered] = useState(false);
  const [angloHovered, setAngloHovered] = useState(false);

  const handleProjectClick = (event, projectType) => {
    if (!streetRef.current) return;
  
    // Get viewport dimensions
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
  
    // Get clicked building's position
    const buildingRect = event.target.getBoundingClientRect();
    const buildingCenterX = buildingRect.left + buildingRect.width / 2;
    const buildingCenterY = buildingRect.top + buildingRect.height / 2;
  
    // Calculate translation to center the clicked building
    let translateX = windowWidth / 2 - buildingCenterX;
    let translateY = windowHeight / 2 - buildingCenterY;
  
    // Add offsets to move the zoom down and to the left based on project
    const offsets = {
      aa: { x: 3250, y: 800 },
      umg: { x: 2900, y: 1100 },
      ibm: { x: 2330, y: 900 },
      turner: { x: 1500, y: 750 },
      bbc: { x: 700, y: 680 },
      monum: { x: -1500, y: 600 },
    };
  
    // Apply specific translation based on projectType
    if (offsets[projectType]) {
      translateX -= offsets[projectType].x;
      translateY += offsets[projectType].y;
    }
  
    // Apply zoom effect with fade-out (start fade immediately with zoom)
    streetRef.current.style.transition = 'transform 2s cubic-bezier(0.42, 0, 1, 1), opacity 2s cubic-bezier(0.42, 0, 1, 1)';
    streetRef.current.style.transform = `translate(${translateX}px, ${translateY}px) scale(8)`; // Zoom in effect
    streetRef.current.style.opacity = 0; // Fade out immediately as zoom starts
  
    // Set the zoom-to-project class to trigger animations
    setZoomToProject(projectType);
  
    // Add the 'visible' class to trigger fade-in after zoom effect (delay by 2 seconds)
    setTimeout(() => {
      const fadeInElement = document.querySelector('.page-fade-in');
      if (fadeInElement) {
        fadeInElement.classList.add('visible');
      }
    }, 1000); // This ensures the fade-in starts after 1 seconds (before navigation)
  
    // Navigate after 2.5 seconds (give enough time for zoom transition to complete)
    setTimeout(() => {
      window.location.href = `/${projectType}`;
    }, 2050); // Slightly longer than the transition to allow the page to load
  };
  

  const generateRandomCars = () => {
    return Array.from({ length: CAR_COUNT }, (_, i) => {
      const isMovingLeft = Math.random() > 0.5;
      const initialX = Math.random() * SCREEN_WIDTH;
      const speed = Math.random() * 2 + 1; // Random speed (1-3)

      return {
        id: i,
        x: initialX,
        y: 0, // Fixed vertical position
        speed,
        isMovingLeft,
        image: CAR_IMAGES[Math.floor(Math.random() * CAR_IMAGES.length)],
      };
    });
  };

  useEffect(() => {
    const quotes = [
      'Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.',
      'The price of success is hard work, dedication to the job at hand, and the determination that whether we win or lose, we have applied the best of ourselves to the task at hand.',
      'Coming together is a beginning; keeping together is progress; working together is success.',
      'Always be yourself, express yourself, have faith in yourself, do not go out and look for a successful personality and duplicate it.'
    ];

    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote); // Set the quote when the component mounts
  }, []); // Empty dependency array ensures this only runs once

  const [cars, setCars] = useState(generateRandomCars());

  useEffect(() => {
    const interval = setInterval(() => {
      setCars((prevCars) =>
        prevCars.map((car) => {
          let newX = car.isMovingLeft ? car.x - car.speed : car.x + car.speed;

          // If the car exits the screen, reset its position to the opposite side
          if (newX < -100) {
            newX = SCREEN_WIDTH + 100; // Re-enter from the right
          } else if (newX > SCREEN_WIDTH + 100) {
            newX = -100; // Re-enter from the left
          }

          return {
            ...car,
            x: newX,
          };
        })
      );
    }, 16); // Roughly 60 FPS

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`parent ${zoomToProject ? "zoom-to-project" : ""}`} ref={streetRef}>
      <section className="street">
        <div className="seagull"></div>
        <div className="balloon">
          <div className="balloon-inner"></div>
        </div>
        <div className="plane">
          <div>
            <div></div>
          </div>
        </div>
        <div className="charing-cross"></div>
        <div className="line"></div>
        <div className="house">
          <div className="quote-of-the-day">
            <h4>Mike's quote of the day...</h4>
            <span className="open">"</span>
            {quote}
            <span className="closed">"</span>
          </div>
          <div className="arch"></div>
        </div>
        {cars.map((car) => (
          <div
            key={car.id}
            className={`car ${car.isMovingLeft ? "reverse-direction" : ""} car${CAR_IMAGES.indexOf(car.image) + 1}`}
            style={{
              position: "absolute",
              left: `${car.x}px`, // Horizontal position
              backgroundImage: `url(${car.image})`,
              transform: "translateY(-50%)", // Centers the car vertically
            }}
          />
        ))}
        <div className="garbage-truck"></div>
        <div className="train"></div>
        <div className="fast-train"></div>
        <div className="sign"></div>
        <div className="tree"></div>
        <div className={`uni ${uniHovered ? "show-job-desc" : ""}`}>
          <section
            className="hover"
            onMouseEnter={() => setUniHovered(true)}
            onMouseLeave={() => setUniHovered(false)}>
            <strong className="border-bottom-none">Ravensbourne College of Design & Communication</strong>
          </section>
          <article className="black">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-ravensbourne" src={RavensLogo} /><br/>
            Mike graduates with an honours degree
            in "Graphic Design & Visual Communication", specialising in 
            "Multi-Media" at "Ravenbourne College of Design & Communication", 
            now known as "Ravenbourne University London"
          </article>
        </div>
        <div className={`brann hover red ${brannHovered ? "show-job-desc" : ""}`} onClick={(e) => handleProjectClick(e, 'monum')}>
          <section
            className="hover"
            onMouseEnter={() => setBrannHovered(true)}
            onMouseLeave={() => setBrannHovered(false)}>
            <strong>Brann/Fullsix</strong>
          </section>
          <article>
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-brann" src={BrannLogo} /><br/>
            Mike starts his career working agency-side
            designing websites for global brands such as
            Guinness, Coca Cola, Barclays, Sainsburys and many others.
            This is where he also starts learning HTML, JavaScript and CSS
          </article>
        </div>
        <div className={`pfizer hover red ${pfizerHovered ? "show-job-desc" : ""}`}>
          <section
            className="hover"
            onMouseEnter={() => setPfizerHovered(true)}
            onMouseLeave={() => setPfizerHovered(false)}>
            <strong className="black border-bottom-none">Pfizer</strong>
          </section>
          <article className="black">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-pfizer" src={PfizerLogo} /><br/>
            Mike goes client-side, managing the Digital Media Department
            within creative services, a small team of .Net devs & Designers.
            This was a hands on role and he got involved in the design and
            front-end development on the Pfizer Intranet
          </article>
        </div>
        <div className={`discovery hover red ${discHovered ? "show-job-desc" : ""}`}>
          <section
            className="hover"
            onMouseEnter={() => setDiscHovered(true)}
            onMouseLeave={() => setDiscHovered(false)}>
            <strong className="black border-bottom-none nowrap">Discovery Channel</strong>
          </section>
          <article className="black">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-discovery" src={DiscLogo} /><br/>
            Mike only stayed at Discovery for a short time on this contract.
            Although it was good experience working with the sales teams, there
            wasn't enough design or developement work to keep him around
          </article>
        </div>
        <div className={`bbc hover red ${bbcHovered ? "show-job-desc" : ""}`} onClick={(e) => handleProjectClick(e, 'bbc')}>
          <section
            className="hover"
            onMouseEnter={() => setBbcHovered(true)}
            onMouseLeave={() => setBbcHovered(false)}>
            <strong>BBC Worldwide</strong>
          </section>
          <article className="right">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-bbc" src={BBCLogo} /><br/>
            Mike starts a contract at BBC Worldwide designing and developing the
            BBC Motion Gallery website, the new TopGear website and interactive
            sales presentation for Topgear TV merchandise
          </article>
        </div>
        <div className={`turner hover red ${tunerHovered ? "show-job-desc" : ""}`} onClick={(e) => handleProjectClick(e, 'turner')}>
          <section
            className="hover"
            onMouseEnter={() => setTurnerHovered(true)}
            onMouseLeave={() => setTurnerHovered(false)}>
            <strong>Turner Inc</strong>
          </section>
          <article className="right">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-turner" src={TurnerLogo} /><br/>
            Mike starts a contract with Turner Broadcasting Inc, designing the Cartoon Network,
            Adult Swim, Boomerang and CNN websites. This contract was back in the days of
            Adobe Flash and a large part of the role was to create and animate various
            CN online gaming portals
          </article>
        </div>
        <div className={`ibm hover red ${ibmHovered ? "show-job-desc" : ""}`} onClick={(e) => handleProjectClick(e, 'ibm')}>
          <section
            className="hover"
            onMouseEnter={() => setIbmHovered(true)}
            onMouseLeave={() => setIbmHovered(false)}>
            <strong>IBM</strong>
          </section>
          <article className="right">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-ibm" src={IBMLogo} /><br/>
            Mike joins IBM’s to work on their cloud-based SaaS products. He collaborates with product managers and 
            engineers to improve usability and accessibility, conducting usability 
            testing to refine workflows and optimize the overall user experience.
          </article>
        </div>
        <div className={`umg hover red ${umgHovered ? "show-job-desc" : ""}`} onClick={(e) => handleProjectClick(e, 'umg')}>
          <section
            className="hover"
            onMouseEnter={() => setUmgHovered(true)}
            onMouseLeave={() => setUmgHovered(false)}>
            <strong>UMG</strong>
          </section>
          <article className="right">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-universal" src={UniversalLogo} /><br/>
            Mike joins an exciting greenfield project at Universal Music, designing and developing
            a suite of applications to manage the production and delivery of music out to digital
            streaming services such as Apple Music, Amazon Music, Deezer, Spotify and many others
          </article>
        </div>
        <div className={`anglo hover red ${angloHovered ? "show-job-desc" : ""}`} onClick={(e) => handleProjectClick(e, 'aa')}>
          <section
            className="hover"
            onMouseEnter={() => setAngloHovered(true)}
            onMouseLeave={() => setAngloHovered(false)}>
            <strong>Anglo American</strong>
          </section>
          <article className="right">
            <img className="pennant-flag" src={PennantFlag} />
            <img className="logo-anglo" src={AngloLogo} /><br/>
            Mike's currently working at Anglo American on a rolling contract. He's designing
            and developing data-rich trading websites for Anglo clients in Angluar and React. He's overseeing 100+ 
            .Net developers whilst building a fully fledged Javascript component library for Anglo American's 
            huge catalogue of customer onboarding and trading applications
          </article>
        </div>
      </section>
      <h1>Hi<span className="ellipsis">...</span></h1>

      <p>I’m <a href="mailto:info@pixelheads.co.uk">Michael King</a> of PixelHeads Limited
        <br />A Senior UX/UI Consultant/Designer and Front-End Developer.<br /> I take
        projects from inception through to delivery
      </p>

      {/* Mobile view */}
      <p className="intro-mobile">I’m <a href="mailto:info@pixelheads.co.uk">Mike King</a> of PixelHeads Limited<br />
        A Senior UX/UI Consultant/Designer and<br />Angular/React Front-End Developer.
      </p>

      <div className="social flex-group flex-center">
          <a href="https://www.linkedin.com/in/michael-king-0481b92" target="blank">
            <img src={LinkedIn} alt="" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=613721312" target="blank">
            <img src={FB} alt="" />
          </a>
          <a href="https://github.com/PixelHeadsLtd" target="blank">
            <img src={GitHub} alt="" />
          </a>
        </div>
    </div>
  );
});

export default Landing;
