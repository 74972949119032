import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import ActiveSection from '../../scripts/active-section';
import NavAngular from '../header/NavAngular';

// Images
import Component from '../../images/interviews/component.png';
import Modules from '../../images/interviews/modules.png';
import DataBinding from '../../images/interviews/data-binding.png';
import Directives from '../../images/interviews/directives.png';
import ServiceExample from '../../images/interviews/service-example.png';
import ServiceComponent from '../../images/interviews/service-comp.png';
import ObservableExample from '../../images/interviews/observable-example.png';
import ObservableHttp from '../../images/interviews/observable-http.png';
import EventEmitter from '../../images/interviews/event-emitter.png';
import EventEmitterParent from '../../images/interviews/event-emitter-parent.png';
import RoutingRoutes from '../../images/interviews/routing-routes.png';
import RoutingTemplate from '../../images/interviews/routing-template.png';
import ReactiveForm from '../../images/interviews/reactive-form.png';
import PipeBuiltIn from '../../images/interviews/pipe-built-in.png';
import PipeCustom from '../../images/interviews/pipe-custom.png';
import Hooks from '../../images/interviews/hooks.png';
import LazyLoading from '../../images/interviews/lazy-loading.png';
import GenerateService from '../../images/interviews/generate-service.png';
import UpdateTypeScript from '../../images/interviews/update-typescript.png';
import UpdateTemplate from '../../images/interviews/update-template.png';
import AddModule from '../../images/interviews/add-module.png';
import Pipe from '../../images/interviews/pipe.png';
import AddPipe from '../../images/interviews/add-pipe.png';
import CreateDirective from '../../images/interviews/create-directive.png';
import UseDirective from '../../images/interviews/use-directive.png';
import DeclareDirective from '../../images/interviews/declare-directive.png';

const AngularConcepts = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <NavAngular ref={menuRef} />
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element className='element'>
          <section id="section-1" className='content-section flex-group flex-center'>
            <ActiveSection index="1" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Components</h2>
                <h3>What</h3>
                <p>
                  Components are the building blocks of an Angular app. Each component 
                  consists of an HTML template, a TypeScript class, and styles.
                </p>
                <h3>Why</h3>
                <p>
                  They help create a structured, modular UI by encapsulating logic and UI elements.
                </p>
                <h3>How</h3>
                  <p>ng generate component my-component</p>
                <p>
                  <img className="mw50" src={Component} />
                </p>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-2" className='content-section flex-group flex-center'>
            <ActiveSection index="2" refToMenu={menuRef}>
              <section className='script text-align-left flex-group flex-center flex-column'>
                <h2>Modules (NgModule)</h2>
                <h3>What</h3>
                <p>
                  Modules organize an Angular app into cohesive blocks.
                </p>
                <h3>Why</h3>
                <p>
                  They enable code organization, lazy loading, and dependency injection.
                </p>
                <h3>How</h3>
                <img className="mw50" src={Modules} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-3" className='content-section flex-group flex-center'>
            <ActiveSection index="3" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Data Binding</h2>
                <h3>What?</h3>
                <p>Binding links the data in the TypeScript class to the template.</p>
                <h3>Why?</h3>
                <p>It keeps the UI in sync with the data model.</p>
                <h3>How?</h3>
                <img className="mw30" src={DataBinding} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-4" className='content-section flex-group flex-center'>
            <ActiveSection index="4" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Directives</h2>
                <h3>What</h3>
                <p>
                  Directives extend HTML by adding behavior to elements.
                </p>
                <h3>Why</h3>
                <p>
                  They enable DOM manipulation and reusable UI logic.
                </p>
                <h3>How</h3>
                <img className="mw50" src={Directives} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-5" className='content-section flex-group flex-center'>
            <ActiveSection index="5" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Services & Dependency Injection (DI)</h2>
                <h3>What?</h3>
                <p>Services handle business logic and data fetching, shared across components.</p>
                <h3>Why?</h3>
                <p>To maintain separation of concerns and avoid redundant code.</p>
                <h3>How?</h3>
                <p>ng generate service myService</p>
                <strong>Service example:</strong>
                <img className="mw50" src={ServiceExample} />
                <strong>Use in a component:</strong>
                <img className="mw50" src={ServiceComponent} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-6" className='content-section flex-group flex-center'>
            <ActiveSection index="6" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Observables & RxJS</h2>
                <h3>What?</h3>
                <p>Observables handle asynchronous operations and event streams.</p>
                <h3>Why?</h3>
                <p>They provide better control over async data (compared to Promises) with operators like map, filter, merge.</p>
                <h3 className="margin-bottom-small">How?</h3>
                <strong>Basic Observable Example in Your Service.ts:</strong>
                <img className="mw50" src={ObservableExample} />
                <strong>HTTP Request with Observables in Your Component.ts</strong>
                <img className="mw50" src={ObservableHttp} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-7" className='content-section flex-group flex-center'>
            <ActiveSection index="7" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>EventEmitters</h2>
                <h3>What?</h3>
                <p>A way for a child component to communicate with a parent.</p>
                <h3>Why?</h3>
                <p>It allows components to be loosely coupled.</p>
                <h3 className="margin-bottom-small">How?</h3>
                <strong>Child Component:</strong>
                <img className="mw50" src={EventEmitter} />
                <strong>Parent Component:</strong>
                <img className="mw50" src={EventEmitterParent} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-8" className='content-section flex-group flex-center'>
            <ActiveSection index="8" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Routing</h2>
                <h3>What?</h3>
                <p>Routing allows navigation between different views in an Angular app.</p>
                <h3>Why?</h3>
                <p>It enables a single-page application experience.</p>
                <h3 className="margin-bottom-small">How?</h3>
                <strong>Define routes:</strong>
                <img className="mw50" src={RoutingRoutes} />
                <strong>Use in a template:</strong>
                <img className="mw50" src={RoutingTemplate} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-9" className='content-section flex-group flex-center'>
            <ActiveSection index="9" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Forms (Reactive & Template-Driven)</h2>
                <h3>What?</h3>
                <p>Angular provides two ways to handle forms:</p>
                <ul className="text-align-left">
                  <li>Template-Driven Forms (simpler, uses ngModel)</li>
                  <li>Reactive Forms (more scalable, uses FormGroup & FormControl)</li>
                </ul>
                <h3>Why?</h3>
                <p>Forms are essential for user input handling.</p>
                <h3 className="margin-bottom-small">How?</h3>
                <strong>Reactive Form Example:</strong>
                <img className="mw50" src={ReactiveForm} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-10" className='content-section flex-group flex-center'>
            <ActiveSection index="10" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Pipes</h2>
                <h3>What?</h3>
                <p>Pipes transform data before displaying it in a template.</p>
                <h3>Why?</h3>
                <p>They make data representation cleaner and more readable.</p>
                <h3 className="margin-bottom-small">How?</h3>
                <strong>Built-in Pipes:</strong>
                <img className="mw50" src={PipeBuiltIn} />
                <strong>Custom Pipe:</strong>
                <img className="mw50" src={PipeCustom} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-11" className='content-section flex-group flex-center'>
            <ActiveSection index="11" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Lifecycle Hooks</h2>
                <h3>What?</h3>
                <p>Hooks let you tap into different stages of a component’s life.</p>
                <h3>Why?</h3>
                <p>They help with initialization, cleanup, and performance optimization.</p>
                <h3 className="margin-bottom-small">How?</h3>
                <strong>Common Hooks:</strong>
                <img className="mw50" src={Hooks} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-12" className='content-section flex-group flex-center'>
            <ActiveSection index="12" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Lazy Loading</h2>
                <h3>What?</h3>
                <p>Lazy loading loads modules only when needed.</p>
                <h3>Why?</h3>
                <p>It improves performance by reducing initial bundle size.</p>
                <h3 className="margin-bottom-small">How?</h3>
                <strong>Define a lazy-loaded module:</strong>
                <p>
                  This code implements lazy loading in Angular. Instead of loading all 
                  modules upfront, it loads <strong className="red">DashboardModule only when the user navigates 
                  to /dashboard.</strong> The import() statement dynamically imports the module, 
                  reducing the initial application bundle size and improving performance.
                </p>
                <img className="mw50" src={LazyLoading} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-13" className='content-section flex-group flex-center'>
            <ActiveSection index="13" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Generate Service</h2>
                <h3 className='margin-top-0'> (ng generate service search)</h3>
                <strong>Handles the API call:</strong>
                <img className="mw50" src={GenerateService} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-14" className='content-section flex-group flex-center'>
            <ActiveSection index="14" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Update Component TypeScript</h2>
                <h3 className='margin-top-0'> (ng generate component search)</h3>
                <strong>Handles user input and calls the service:</strong>
                <img className="mw50" src={UpdateTypeScript} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-15" className='content-section flex-group flex-center'>
            <ActiveSection index="15" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Update Component Template</h2>
                <img className="mw50" src={UpdateTemplate} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-16" className='content-section flex-group flex-center'>
            <ActiveSection index="16" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Add the HttpClientModule to app.module</h2>
                <img className="mw50" src={AddModule} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-17" className='content-section flex-group flex-center'>
            <ActiveSection index="17" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Create the Pipe</h2>
                <strong>ng generate pipe search</strong>
                <img className="mw50" src={Pipe} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-18" className='content-section flex-group flex-center'>
            <ActiveSection index="18" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Add the Pipe to Search Template for our Search</h2>
                <img className="mw50" src={AddPipe} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-19" className='content-section flex-group flex-center'>
            <ActiveSection index="19" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Create Directive</h2>
                <strong>ng generate directive highlight</strong>
                <img className="mw50" src={CreateDirective} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-20" className='content-section flex-group flex-center'>
            <ActiveSection index="20" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Use the Directive in our Template</h2>
                <img className="mw50" src={UseDirective} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-21" className='content-section flex-group flex-center'>
            <ActiveSection index="21" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Declare Directive in our Module</h2>
                <img className="mw50" src={DeclareDirective} />
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default AngularConcepts;