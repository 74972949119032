import { React } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AddClass from './scripts/transpose-menu';
import AOS from 'aos';
import { Helmet } from 'react-helmet';

// pages
import AA from './components/pages/AA';
import UMG from './components/pages/UMG';
import Turner from './components/pages/Turner';
import BBC from './components/pages/BBC';
import Monum from './components/pages/Monum';
import Setanta from './components/pages/Setanta';
import IBM from './components/pages/IBM';
import PrivateJobs from './components/pages/Private';
import Home from './components/pages/Home';
import BlogHome from './components/pages/blog/components/Layout';
import PostsList from "./components/pages/blog/features/posts/PostsList";
import AddPostForm from "./components/pages/blog/features/posts/AddPostForm";
import SinglePostPage from "./components/pages/blog/features/posts/SinglePostPage";
import EditPostForm from "./components/pages/blog/features/posts/EditPostForm";
import UsersList from "./components/pages/blog/features/users/UsersList";
import UserPage from './components/pages/blog/features/users/UserPage';

import Layout from "./components/interviews/Layout";
import Interviews from './components/interviews/Interviews';
import AngularConcepts from './components/interviews/AngularConcepts';
import UnitTesting from './components/interviews/UnitTesting';
import ReactConcepts from './components/interviews/ReactConcepts';
import NextJSConcepts from './components/interviews/NextJSConcepts';
import VueConcepts from './components/interviews/VueConcepts';
import ProjectWalkthrough from './components/interviews/ProjectWalkthrough';

AOS.init();

function App() {
  return (
    <div className="App">
      <AddClass>
        <Routes>
        <Route path="/" element={
            <>
              <Helmet>
                <title>Home | UX Designer & Front-End Developer</title>
                <meta name="description" content="Welcome to my portfolio. I'm a UX Designer and Front-End Developer specializing in user experiences and web applications." />
                <meta name="keywords" content="UX Designer, Front-End Developer, Web Developer, UX/UI, React Developer, Angular Developer" />
              </Helmet>
              <Home />
            </>
          } />
          <Route path='/AA' element={<AA />} />
          <Route path='/UMG' element={<UMG />} />
          <Route path='/Turner' element={<Turner />} />
          <Route path='/BBC' element={<BBC />} />
          <Route path='/Monum' element={<Monum />} />
          <Route path='/Setanta' element={<Setanta />} />
          <Route path='/IBM' element={<IBM />} />
          <Route path='/Private' element={<PrivateJobs />} />

          {/* Interviews */}
          <Route path="/interviews" element={<Layout />}>
            <Route index element={<Interviews />} />
            <Route path="AngularConcepts" element={<AngularConcepts />} />
            <Route path="UnitTesting" element={<UnitTesting />} />
            <Route path="ReactConcepts" element={<ReactConcepts />} />
            <Route path="NextJSConcepts" element={<NextJSConcepts />} />
            <Route path="VueConcepts" element={<VueConcepts />} />
            <Route path="ProjectWalkthrough" element={<ProjectWalkthrough />} />
          </Route>

          {/* Blog */}
          <Route exact path="/blog-home" element={<BlogHome />}>
            <Route index element={<PostsList />} />
            <Route path="post">
              <Route index element={<AddPostForm />} />
              <Route path=":postId" element={<SinglePostPage />} />
              <Route path="edit/:postId" element={<EditPostForm />} />
            </Route>
            <Route path="user">
              <Route index element={<UsersList />} />
              <Route path=":userId" element={<UserPage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AddClass>
    </div>
  );
}

export default App;