import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function HeaderInterview({ refToMenu }) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    // Set active link based on current pathname
    if (location.pathname === "/interviews") {
      setActiveLink("/interviews");
    } else if (location.pathname.startsWith("/interviews/AngularConcepts")) {
      setActiveLink("/interviews/AngularConcepts");
    } else if (location.pathname.startsWith("/interviews/UnitTesting")) {
      setActiveLink("/interviews/UnitTesting");
    }
  }, [location.pathname]); // Runs when pathname changes


  useEffect(() => {
    if (refToMenu?.current) {
      refToMenu.current.setState({ isActiveIndex: activeLink });
    }
  }, [activeLink, refToMenu]);

  return (
    <nav className="flex-group flex-start flex-nowrap">
      <Link className="black" to="/">Home</Link>
      <Link
        className={activeLink === "/interviews" ? "active" : ""}
        to="/interviews"
        onClick={() => setActiveLink("/interviews")}
      >
        Experience
      </Link>
      <Link
        className={activeLink === "/interviews/ProjectWalkthrough" ? "active" : ""}
        to="/interviews/ProjectWalkthrough"
        onClick={() => setActiveLink("/interviews/ProjectWalkthrough")}
      >
        Project Walkthrough
      </Link>
      <div className="divider"></div>
      <Link
        className={activeLink === "/interviews/AngularConcepts" ? "active" : ""}
        to="/interviews/AngularConcepts"
        onClick={() => setActiveLink("/interviews/AngularConcepts")}
      >
        Angular Concepts
      </Link>
      <Link
        className={activeLink === "/interviews/UnitTesting" ? "active" : ""}
        to="/interviews/UnitTesting"
        onClick={() => setActiveLink("/interviews/UnitTesting")}
      >
        Unit Testing
      </Link>
      <div className="divider"></div>
      <Link
        className={activeLink === "/interviews/ReactConcepts" ? "active" : ""}
        to="/interviews/ReactConcepts"
        onClick={() => setActiveLink("/interviews/ReactConcepts")}
      >
        React Concepts
      </Link>
      <Link
        className={activeLink === "/interviews/NextJSConcepts" ? "active" : ""}
        to="/interviews/NextJSConcepts"
        onClick={() => setActiveLink("/interviews/NextJSConcepts")}
      >
        NextJS Concepts
      </Link>
      <div className="divider"></div>
      <Link
        className={activeLink === "/interviews/VueConcepts" ? "active" : ""}
        to="/interviews/VueConcepts"
        onClick={() => setActiveLink("/interviews/VueConcepts")}
      >
        Vue Concepts
      </Link>
    </nav>
  );
}
