import React, { useState, useEffect } from 'react';

const NavAngular = () => {
  // Retrieve last active section from localStorage (default to "1")
  const [isActiveIndex, setIsActiveIndex] = useState(localStorage.getItem('activeAngularSection') || "1");

  const toggleActiveClass = (index) => {
    setIsActiveIndex(index);
    localStorage.setItem('activeAngularSection', index); // Store in localStorage
    document.getElementById(`section-${index}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  // Function to observe sections on scroll
  useEffect(() => {
    const sections = document.querySelectorAll('.content-section'); // Target all sections

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id.replace('section-', '');
            setIsActiveIndex(sectionId);
            localStorage.setItem('activeAngularSection', sectionId); // Store new section
          }
        });
      },
      { threshold: 0.6 } // Trigger when 60% of section is visible
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  // Scroll to the last active section when the component mounts
  useEffect(() => {
    const savedIndex = localStorage.getItem('activeAngularSection');
    if (savedIndex) {
      document.getElementById(`section-${savedIndex}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="interviews-nav-section">
      <nav className="interviews-nav flex-group flex-column mw10">
        <h4>Angular Concepts</h4>
        <a 
          className={`${isActiveIndex === "1" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("1"); }} 
          href="#section-1"
        >
          Components
        </a>
        <a 
          className={`${isActiveIndex === "2" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("2"); }} 
          href="#section-2"
        >
          Modules
        </a>
        <a 
          className={`${isActiveIndex === "3" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("3"); }} 
          href="#section-3"
        >
          Data Binding
        </a>
        <a 
          className={`${isActiveIndex === "4" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("4"); }} 
          href="#section-4"
        >
          Directives
        </a>
        <a 
          className={`${isActiveIndex === "5" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("5"); }} 
          href="#section-5"
        >
          Services & DI
        </a>
        <a 
          className={`${isActiveIndex === "6" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("6"); }} 
          href="#section-6"
        >
          Observables & RxJS
        </a>
        <a 
          className={`${isActiveIndex === "7" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("7"); }} 
          href="#section-7"
        >
          EventEmitters
        </a>
        <a 
          className={`${isActiveIndex === "8" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("8"); }} 
          href="#section-8"
        >
          Routing
        </a>
        <a 
          className={`${isActiveIndex === "9" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("9"); }} 
          href="#section-9"
        >
          Forms
        </a>
        <a 
          className={`${isActiveIndex === "10" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("10"); }} 
          href="#section-10"
        >
          Pipes
        </a>
        <a 
          className={`${isActiveIndex === "11" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("11"); }} 
          href="#section-11"
        >
          Lifecycle Hooks
        </a>
        <a 
          className={`${isActiveIndex === "12" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("12"); }} 
          href="#section-12"
        >
          Lazy Loading
        </a>

        <h4 className="margin-top-large">Create a search</h4>
        <a 
          className={`${isActiveIndex === "13" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("13"); }} 
          href="#section-13"
        >
          Generate Service
        </a>
        <a 
          className={`${isActiveIndex === "14" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("14"); }} 
          href="#section-14"
        >
          Update Comp TypeScript
        </a>
        <a 
          className={`${isActiveIndex === "15" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("15"); }} 
          href="#section-15"
        >
          Update Comp Template
        </a>
        <a 
          className={`${isActiveIndex === "16" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("16"); }} 
          href="#section-16"
        >
          Add HttpClientModule
        </a>

        <h4 className="margin-top-large">Create a pipe</h4>
        <a 
          className={`${isActiveIndex === "17" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("17"); }} 
          href="#section-17"
        >
          Create the pipe
        </a>
        <a 
          className={`${isActiveIndex === "18" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("18"); }} 
          href="#section-18"
        >
          Add pipe to search template
        </a>

        <h4 className="margin-top-large">Create a directive</h4>
        <a 
          className={`${isActiveIndex === "19" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("19"); }} 
          href="#section-19"
        >
          Create the directive
        </a>
        <a 
          className={`${isActiveIndex === "20" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("20"); }} 
          href="#section-20"
        >
          Use the Directive
        </a>
        <a 
          className={`${isActiveIndex === "21" ? 'active' : ''} flex-group flex-center`} 
          onClick={(e) => { e.preventDefault(); toggleActiveClass("21"); }} 
          href="#section-21"
        >
          Declare in module
        </a>
      </nav>
    </div>
  );
};

export default NavAngular;
