import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import ActiveSection from '../../scripts/active-section';
import NavReact from '../header/NavReact';

// Images
import FunComp from '../../images/interviews/funct-comp.png';
import ClassComp from '../../images/interviews/class-comp.png';
import UsingComp from '../../images/interviews/using-comp.png';
import JSX from '../../images/interviews/jsx.png';
import JSXComp from '../../images/interviews/jsx-compiled.png';
import PropsPass from '../../images/interviews/props-pass.png';
import PropsReceive from '../../images/interviews/props-receive.png';
import PropsDestruct from '../../images/interviews/props-destruct.png';
import UseState from '../../images/interviews/use-state.png';
import EventHand from '../../images/interviews/event-hand.png';
import CondRendIfElse from '../../images/interviews/cond-rend-ifelse.png';
import CondRendOperator from '../../images/interviews/cond-rend-operator.png';
import ListsKeys from '../../images/interviews/lists-keys.png';
import UseEffect from '../../images/interviews/use-effect.png';
import CreateContext from '../../images/interviews/create-context.png';
import ProvideContext from '../../images/interviews/provide-context.png';
import ConsumeContext from '../../images/interviews/consume-context.png';
import InstallRoute from '../../images/interviews/install-route.png';
import DefineRoute from '../../images/interviews/define-route.png';
import NavigateRoute from '../../images/interviews/navigate-route.png';
import InstallRedux from '../../images/interviews/install-redux.png';
import CreateStore from '../../images/interviews/create-store.png';
import UseRedux from '../../images/interviews/use-redux.png';
import CustomHooks from '../../images/interviews/custom-hooks.png';

import ProjStructOne from '../../images/interviews/proj-struct-one.png';
import ProjStructTwo from '../../images/interviews/proj-struct-two.png';
import ReduxGlobStateOne from '../../images/interviews/redux-global-state-one.png';
import ReduxGlobStateTwo from '../../images/interviews/redux-global-state-two.png';
import ContextApiTheme from '../../images/interviews/context-api-theme.png';
import CustomHookCounter from '../../images/interviews/custom-hook-counter.png';
import ProjectComponentOne from '../../images/interviews/project-component-one.png';
import ProjectComponentTwo from '../../images/interviews/project-component-two.png';
import ProjectComponentThree from '../../images/interviews/project-component-three.png';
import ProjectPageOne from '../../images/interviews/project-page-one.png';
import ProjectPageTwo from '../../images/interviews/project-page-two.png';
import ProjectRouting from '../../images/interviews/project-routing.png';
import ProjectIndex from '../../images/interviews/project-index.png';

const ReactConcepts = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <NavReact ref={menuRef} />
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element className='element'>
          <section id="section-1" className='content-section flex-group flex-center'>
            <ActiveSection index="1" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Components</h2>
                <h3>What</h3>
                <p>
                  Components are the fundamental building blocks of a React app. 
                  They can be functional or class-based.
                </p>
                <h3>Why</h3>
                <p>
                  They allow for reusable, modular UI elements.
                </p>
                <h3>How</h3>
                <strong>Functional Component (modern approach):</strong>
                <img className="mw50" src={FunComp} />
                <strong>Class Component (older approach):</strong>
                <img className="mw50" src={ClassComp} />
                <strong>Using a Component:</strong>
                <img className="mw50" src={UsingComp} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-2" className='content-section flex-group flex-center'>
            <ActiveSection index="2" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>JSX (JavaScript XML)</h2>
                <h3>What</h3>
                <p>
                  JSX is a syntax extension that lets you write HTML-like code inside JavaScript.
                </p>
                <h3>Why</h3>
                <p>
                  It makes UI more readable and allows embedding logic within markup.
                </p>
                <h3>How</h3>
                <strong>JSX Example:</strong>
                <img className="mw50" src={JSX} />
                <strong>JSX is compiled to JavaScript:</strong>
                <img className="mw50" src={JSXComp} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-3" className='content-section flex-group flex-center'>
            <ActiveSection index="3" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Props (Properties)</h2>
                <h3>What</h3>
                <p>
                  Props are inputs passed to components.
                </p>
                <h3>Why</h3>
                <p>
                  They allow data to flow from parent to child components.
                </p>
                <h3>How</h3>
                <strong>Passing Props:</strong>
                <img className="mw50" src={PropsPass} />
                <strong>Receiving Props:</strong>
                <img className="mw50" src={PropsReceive} />
                <strong>Destructuring Props:</strong>
                <img className="mw50" src={PropsDestruct} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-4" className='content-section flex-group flex-center'>
            <ActiveSection index="4" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>State & useState Hook</h2>
                <h3>What</h3>
                <p>
                  State is data managed within a component.
                </p>
                <h3>Why</h3>
                <p>
                  It allows components to dynamically update when the data changes.
                </p>
                <h3>How</h3>
                <strong>useState Example:</strong>
                <img className="mw50" src={UseState} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-5" className='content-section flex-group flex-center'>
            <ActiveSection index="5" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Event Handling</h2>
                <h3>What</h3>
                <p>
                  React handles user interactions via events (clicks, form submissions, etc.).
                </p>
                <h3>Why</h3>
                <p>
                  It provides a way to handle UI interactions declaratively.
                </p>
                <h3>How</h3>
                <strong>Event Handling Example:</strong>
                <img className="mw50" src={EventHand} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-6" className='content-section flex-group flex-center'>
            <ActiveSection index="6" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Conditional Rendering</h2>
                <h3>What</h3>
                <p>
                  It allows components to display different UI based on conditions.
                </p>
                <h3>Why</h3>
                <p>
                  To dynamically show/hide elements.
                </p>
                <h3>How</h3>
                <strong>Using if-else (inline conditional operator):</strong>
                <img className="mw50" src={CondRendIfElse} />
                <strong>Using && operator:</strong>
                <img className="mw50" src={CondRendOperator} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-7" className='content-section flex-group flex-center'>
            <ActiveSection index="7" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Lists & Keys</h2>
                <h3>What</h3>
                <p>
                  Lists are rendered using .map(), and keys help React track elements efficiently.
                </p>
                <h3>Why</h3>
                <p>
                  Keys improve performance by helping React identify changes.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={ListsKeys} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-8" className='content-section flex-group flex-center'>
            <ActiveSection index="8" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>useEffect Hook</h2>
                <h3>What</h3>
                <p>
                  useEffect is a side-effect hook (for API calls, event listeners, etc.).
                </p>
                <h3>Why</h3>
                <p>
                  It replaces componentDidMount, componentDidUpdate, and 
                  componentWillUnmount in class components.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={UseEffect} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-9" className='content-section flex-group flex-center'>
            <ActiveSection index="9" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Context API (State Management)</h2>
                <h3>What</h3>
                <p>
                  Context API provides global state management without props drilling.
                </p>
                <h3>Why</h3>
                <p>
                  To avoid passing props deeply in nested components.
                </p>
                <h3>How</h3>
                <strong>Create Context:</strong>
                <img className="mw50" src={CreateContext} />
                <strong>Provide Context:</strong>
                <img className="mw50" src={ProvideContext} />
                <strong>Consume Context:</strong>
                <img className="mw50" src={ConsumeContext} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-10" className='content-section flex-group flex-center'>
            <ActiveSection index="10" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>React Router (Navigation)</h2>
                <h3>What</h3>
                <p>
                  React Router manages page navigation.
                </p>
                <h3>Why</h3>
                <p>
                  It enables single-page applications (SPA) with client-side routing.
                </p>
                <h3>How</h3>
                <strong>Install React Router:</strong>
                <img className="mw50" src={InstallRoute} />
                <strong>Define Routes:</strong>
                <img className="mw50" src={DefineRoute} />
                <strong>Navigation:</strong>
                <img className="mw50" src={NavigateRoute} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-11" className='content-section flex-group flex-center'>
            <ActiveSection index="11" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Redux (Global State Management)</h2>
                <h3>What</h3>
                <p>
                  Redux is a state management library.
                </p>
                <h3>Why</h3>
                <p>
                  Useful for large-scale applications where state is shared across multiple components.
                </p>
                <h3>How</h3>
                <div className='flex-group flex-start flex-nowrap'>
                  <div className='flex-group flex-column'>
                    <strong>Install Redux Toolkit:</strong>
                    <img className="mw40" src={InstallRedux} />
                    <strong>Create Store:</strong>
                    <img className="mw40" src={CreateStore} />
                  </div>
                  <div className='flex-group flex-column'>
                    <strong>Use Redux in Component:</strong>
                    <img className="mw40" src={UseRedux} />
                  </div>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-12" className='content-section flex-group flex-center'>
            <ActiveSection index="12" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Custom Hooks</h2>
                <h3>What</h3>
                <p>
                  Custom hooks are reusable functions that use other hooks.
                </p>
                <h3>Why</h3>
                <p>
                  To avoid code duplication and keep components clean.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={CustomHooks} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-13" className='content-section flex-group flex-center'>
            <ActiveSection index="13" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Project Overview</h2>
                <strong>We’ll build a Theme Switcher App where:</strong>
                <ul className='no-bullets margin-top-small'>
                  <li>1. Users can toggle between Light and Dark themes.</li>
                  <li>2. The theme is managed globally using Context API and Redux.</li>
                  <li>3. It has multiple components, props, state, and events.</li>
                  <li>4. Uses useEffect, custom hooks, and React Router for navigation.</li>
                </ul>
                <strong>Example:</strong>
                <img className="mw50" src={ProjStructOne} />
                <strong>Install Dependencies:</strong>
                <img className="mw50" src={ProjStructTwo} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-14" className='content-section flex-group flex-center'>
            <ActiveSection index="14" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Redux: Global State for Theme</h2>
                <strong>src/redux/themeSlice.js:</strong>
                <img className="mw50" src={ReduxGlobStateOne} />
                <strong>src/redux/store.js</strong>
                <img className="mw50" src={ReduxGlobStateTwo} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-15" className='content-section flex-group flex-center'>
            <ActiveSection index="15" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Context API for Theme (Optional)</h2>
                <strong>src/context/ThemeContext.js:</strong>
                <img className="mw50" src={ContextApiTheme} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-16" className='content-section flex-group flex-center'>
            <ActiveSection index="16" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Custom Hook: Counter</h2>
                <p>
                  In our example, the counter is a separate feature that isn't necessary for 
                  the theme switcher itself. It's just here to demonstrate additional 
                  React concepts like state management, event handling, and custom hooks.</p>
                <strong>src/hooks/useCounter.js:</strong>
                <img className="mw50" src={CustomHookCounter} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-17" className='content-section flex-group flex-center'>
            <ActiveSection index="17" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Components</h2>
                <div className='flex-group flex-start flex-nowrap'>
                  <div className='flex-group flex-column'>
                    <strong>src/components/Header.js:</strong>
                    <img className="mw40" src={ProjectComponentOne} />
                    <strong>src/components/Button.js:</strong>
                    <img className="mw40" src={ProjectComponentTwo} />
                  </div>
                  <div className='flex-group flex-column'>
                    <strong>src/components/ThemeToggle.js:</strong>
                    <img className="mw40" src={ProjectComponentThree} />
                  </div>
                </div>
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-18" className='content-section flex-group flex-center'>
            <ActiveSection index="18" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Pages</h2>
                <strong>src/pages/Home.js:</strong>
                <img className="mw50" src={ProjectPageOne} />
                <strong>src/pages/About.js:</strong>
                <img className="mw50" src={ProjectPageTwo} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-19" className='content-section flex-group flex-center'>
            <ActiveSection index="19" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>App Component (Routing & Theme Management)</h2>
                <strong>src/App.js:</strong>
                <img className="mw50" src={ProjectRouting} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-20" className='content-section flex-group flex-center'>
            <ActiveSection index="20" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Index.js (Connecting Redux)</h2>
                <strong>src/index.js:</strong>
                <img className="mw50" src={ProjectIndex} />
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default ReactConcepts;