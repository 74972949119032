import React from 'react';
import ReactBody from 'react-body';
import { StickyContainer } from 'react-sticky';
import { Element } from 'react-scroll';
import ActiveSection from '../../scripts/active-section';
import NavVue from '../header/NavVue';

// Images
import VueComp from '../../images/interviews/vue-comp.png';
import TempSyn from '../../images/interviews/temp-syn.png';
import DataReactivity from '../../images/interviews/data-reactivity.png';
import EventHandling from '../../images/interviews/event-handling.png';
import ComputedProps from '../../images/interviews/computed-props.png';
import PropsEmits from '../../images/interviews/props-emits.png';
import ParentPropsEmits from '../../images/interviews/parent-props-emit.png';
import LifeCylesHooks from '../../images/interviews/lifecycle-hooks.png';
import VueRouter from '../../images/interviews/vue-router.png';
import RouterSetup from '../../images/interviews/router-setup.png';
import VueX from '../../images/interviews/vuex.png';
import VueXStore from '../../images/interviews/vuex-store.png';

import InstallVue from '../../images/interviews/install-vue.png';
import StorePinia from '../../images/interviews/store-pinia.png';
import VueProjComp from '../../images/interviews/vue-proj-comp.png';
import VueProjCompNav from '../../images/interviews/vue-proj-comp-nav.png';
import VueHome from '../../images/interviews/vue-home.png';
import VueAbout from '../../images/interviews/vue-about.png';
import VueRouterSetup from '../../images/interviews/vue-router-setup.png';
import AppVue from '../../images/interviews/app-vue.png';
import VueEntry from '../../images/interviews/vue-entry.png';
import VueRunApp from '../../images/interviews/vue-run-app.png';

const VueConcepts = () => {
  var menuRef = React.createRef();

  return (
    <section className="interviews-content">
      <ReactBody className="interviews-page" />
      <NavVue ref={menuRef} />
      <section className='flex-group flex-align-center'>
      <StickyContainer>
        <Element className='element'>
          <section id="section-1" className='content-section flex-group flex-center'>
            <ActiveSection index="1" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Components</h2>
                <h3>What</h3>
                <p>
                  Reusable UI elements, like in React and Angular.
                </p>
                <h3>Why</h3>
                <p>
                  Helps modularize and reuse code.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={VueComp} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-2" className='content-section flex-group flex-center'>
            <ActiveSection index="2" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Template Syntax & Directives</h2>
                <h3>What</h3>
                <p>
                  Vue extends HTML with directives (v-if, v-for, etc.).
                </p>
                <h3>Why</h3>
                <p>
                  Enables dynamic UI updates.Helps modularize and reuse code.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={TempSyn} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-3" className='content-section flex-group flex-center'>
            <ActiveSection index="3" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Data & Reactivity</h2>
                <h3>What</h3>
                <p>
                  Vue automatically tracks state changes.
                </p>
                <h3>Why</h3>
                <p>
                  Ensures UI stays in sync with data.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={DataReactivity} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-4" className='content-section flex-group flex-center'>
            <ActiveSection index="4" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Event Handling</h2>
                <h3>What</h3>
                <p>
                  Handles user interactions (click, input, etc.).
                </p>
                <h3>Why</h3>
                <p>
                  Enables interactive applications.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={EventHandling} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-5" className='content-section flex-group flex-center'>
            <ActiveSection index="5" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Computed Properties & Watchers</h2>
                <h3>What</h3>
                <p>
                  Computed properties reactively derive values; watchers track state changes.
                </p>
                <h3>Why</h3>
                <p>
                  Optimizes performance by caching values.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={ComputedProps} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-6" className='content-section flex-group flex-center'>
            <ActiveSection index="6" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Props & Emits (Parent-Child Communication)</h2>
                <h3>What</h3>
                <p>
                  Props send data down; Emits send events up.
                </p>
                <h3>Why</h3>
                <p>
                  Facilitates component communication.
                </p>
                <h3>How</h3>
                <strong>Child Component (Child.vue):</strong>
                <img className="mw50" src={PropsEmits} />
                <strong>Parent Component:</strong>
                <img className="mw50" src={ParentPropsEmits} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-7" className='content-section flex-group flex-center'>
            <ActiveSection index="7" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Lifecycle Hooks</h2>
                <h3>What</h3>
                <p>
                  Special functions executed at different component stages.
                </p>
                <h3>Why</h3>
                <p>
                  Useful for fetching data, setting up event listeners, etc.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={LifeCylesHooks} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-8" className='content-section flex-group flex-center'>
            <ActiveSection index="8" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Vue Router (Navigation)</h2>
                <h3>What</h3>
                <p>
                  Enables navigation between pages.
                </p>
                <h3>Why</h3>
                <p>
                  Makes Vue a Single Page Application (SPA).
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={VueRouter} />
                <strong>Router Setup:</strong>
                <img className="mw50" src={RouterSetup} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-9" className='content-section flex-group flex-center'>
            <ActiveSection index="9" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Vuex / Pinia (State Management)</h2>
                <h3>What</h3>
                <p>
                  Centralized state management (like Redux in React).Enables navigation between pages.
                </p>
                <h3>Why</h3>
                <p>
                  Avoids prop drilling and manages global state.
                </p>
                <h3>How</h3>
                <strong>Example:</strong>
                <img className="mw50" src={VueX} />
                <strong>Using Store:</strong>
                <img className="mw50" src={VueXStore} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-10" className='content-section flex-group flex-center'>
            <ActiveSection index="10" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Install & Store (Pinia)</h2>
                <strong>Install Vue:</strong>
                <img className="mw50" src={InstallVue} />
                <strong>src/components/Navbar.vue:</strong>
                <img className="mw50" src={StorePinia} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-11" className='content-section flex-group flex-center'>
            <ActiveSection index="11" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Components</h2>
                <strong>src/components/ThemeToggle.vue:</strong>
                <img className="mw50" src={VueProjComp} />
                <strong>src/components/Navbar.vue:</strong>
                <img className="mw50" src={VueProjCompNav} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-12" className='content-section flex-group flex-center'>
            <ActiveSection index="12" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Pages</h2>
                <strong>src/views/Home.vue:</strong>
                <img className="mw50" src={VueHome} />
                <strong>src/views/About.vue:</strong>
                <img className="mw50" src={VueAbout} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-13" className='content-section flex-group flex-center'>
            <ActiveSection index="13" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Router Setup</h2>
                <strong>src/router/index.js:</strong>
                <img className="mw50" src={VueRouterSetup} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-14" className='content-section flex-group flex-center'>
            <ActiveSection index="14" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>App.vue</h2>
                <strong>src/App.vue:</strong>
                <img className="mw50" src={AppVue} />
              </section>
            </ActiveSection>
          </section>
        </Element>
        <Element className='element'>
          <section id="section-15" className='content-section flex-group flex-center'>
            <ActiveSection index="15" refToMenu={menuRef}>
              <section className='script flex-group flex-center flex-column'>
                <h2>Main Entry & Run App</h2>
                <strong>src/main.js:</strong>
                <img className="mw50" src={VueEntry} />
                <strong>Run the App:</strong>
                <img className="mw50" src={VueRunApp} />
              </section>
            </ActiveSection>
          </section>
        </Element>
      </StickyContainer>
      </section>
    </section>
  );
};

export default VueConcepts;